<template>
    <div id="testimonialCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          class="carousel-item"
          v-for="(testimonial, index) in testimonials"
          :key="index"
          :class="{ active: index === 0 }"
        >
          <div class="testimonial-slide">
            <div class="testimonial-slide-rating mb-3">
              <i class="bi bi-star-fill" v-for="n in 5" :key="n"></i>
            </div>
            <div class="testimonial-slide-body">
              <h4 class="fnt-20 fnt-weight-700 my-3">{{ t(testimonial.title) }}</h4>
              <p>{{ t(testimonial.body) }}</p>
            </div>
            <div class="testimonial-slide-user mt-4">
              <div class="user-img">
                <img :src="testimonial.userImage" alt="" class="rounded-circle">
              </div>
              <div class="about-user">
                <h5 class="fnt-18 fnt-weight-700 mb-1">{{ testimonial.userName }}</h5>
                <p class="mb-0">{{ t(testimonial.userOccupation) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#testimonialCarousel"
      data-bs-slide="prev"
    >
      <i class="bi bi-arrow-left"></i>
      <span class="visually-hidden">{{ t('home.previous') }}</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#testimonialCarousel"
      data-bs-slide="next"
    >
      <i class="bi bi-arrow-right"></i>
      <span class="visually-hidden">{{ t('home.next') }}</span>
    </button>
    </div>
  </template>
  
  <script setup>
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const testimonials = [
    {
      title: 'testimonials.great-to-use-for-learning-spanish',
      body: 'testimonials.i-find-this-app-incredibly-simple-to-use',
      userImage: '@/assets/images/testimonial-user.png',
      userName: 'Alan E',
      userOccupation: 'testimonials.business-owner'
    },
    {
      title: 'testimonials.incredibly-efficient',
      body: 'testimonials.ive-studied-many-languages-but-since-switching-to',
      userImage: '@/assets/images/testimonial-user.png',
      userName: 'Tani H',
      userOccupation: 'testimonials.language-enthusiast'
    },
    // Add more testimonials as needed
  ]
  </script>
  
  <style scoped>
  
  .testimonial-slide-rating i {color: #ffcc00;margin-right: 5px;}
  .testimonial-slide .user-img img {width: 67px;height: 67px;object-fit: cover;}
  .testimonial-slide-user .user-img{float: left;}
  .testimonial-slide-user .about-user{ padding-left: 85px;padding-top: 10px;}
  .carousel-control-prev, .carousel-control-next{
    right: 0;
    left: unset;
    top: unset;
    display: inline-block;
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .carousel-control-prev{right: 70px;}
  .bi-arrow-left, .bi-arrow-right {font-size: 1.5rem;}
  .bi-arrow-left{color:#dee1e6;}
  .bi-arrow-right{color:#2500f9;}
  .carousel-control-prev:hover .bi-arrow-left, .carousel-control-next:hover .bi-arrow-right{color:#2500f9;}
  </style>
  
