import { useAuthStore } from '@/stores/auth.store'

import { env } from '@/helpers/env.js'

const baseEnv = env

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
  patch: request('PATCH'),
}

export const getBaseUrl = (url) =>  {
  if (!url.startsWith('https://') && !url.startsWith('http://')) {
    return `${baseEnv.VUE_APP_API_BASE_URL}${url}`
  }
  return url
}

export const getWsBaseUrl = (url) =>  {
  if (!url.startsWith('https://') && !url.startsWith('http://')) {
    return `${baseEnv.VUE_APP_WS_BASE_URL}${url}`
  }
  return url
}

export function getWebsocket(url) {
  return new WebSocket(getWsBaseUrl(url) + '?token=' + useAuthStore().user.token)
}

function request(method) {
  return (url, body, additionalOptions) => {
    // if URL does not start with https, prepend `${import.meta.env.VITE_API_BASE_URL}` to it
    url = getBaseUrl(url)
    const requestOptions = {
      method,
      headers: authHeader(url),
    }
    if (typeof additionalOptions === "undefined") {
      additionalOptions = {}
    }
    for (const [key, value] of Object.entries(additionalOptions)) {
      console.log(key, value)
      requestOptions[key] = value
    }
    if (body) {
      requestOptions.headers['Content-Type'] = 'application/json'
      requestOptions.body = JSON.stringify(body)
    }
    return fetch(url, requestOptions).then(handleResponse)
  }
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const { user } = useAuthStore()
  const isLoggedIn = !!user?.token
  const isApiUrl = url.startsWith(`${baseEnv.VUE_APP_API_BASE_URL}`)
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Token ${user.token}` }
  } else {
    return {}
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      const { user, logout } = useAuthStore()
      if ([401, 403].includes(response.status) && user) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        logout()
      } else if ([400, 405].includes(response.status)) {
        // handle a django authentication failed case
        const error = (data && data.message) || (data && data.detail) || (data && data.non_field_errors) || response.statusText
        return Promise.reject(error)
      }

      const error = (data && data.message) || (data && data.detail) || (data && data.non_field_errors) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
