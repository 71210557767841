<script setup>
  import { ref, onMounted, toRaw, defineProps, defineEmits } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useI18n } from 'vue-i18n'
  import { standardizeTime, standardizeDate, isAndroidApp, capitalizeFirstLetter } from '@/helpers/utils.js'
  const { t } = useI18n()
  const authStore = ref(false)
  authStore.value = useAuthStore()
  const isLoading = ref(true)
  const customer = ref(null)
  const errorStr = ref(null)
  const storeError = ref(null)
  const products = ref(null)
  const monthly = ref(null)
  const annual = ref(null)
  const showPricingPlans = ref(false)
  const currentProduct = ref(false)
  const currentSubscriptionType = ref(false)
  const androidOfferings = ref([])
  import { Purchases } from "@revenuecat/purchases-capacitor"
  const props = defineProps({
    billingInfo: Object,
    sendCompletion: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "tab"
    }
  })
  const billing = ref(props.billingInfo)
  const mode = ref(props.mode)
  const emit = defineEmits(['sendCompleted'])

  async function updateProducts() {
    try {
      const productIds = ["google_7_day_free_9999_annual", "google_7_day_free_999_monthly"]
      const offerings = await Purchases.getProducts({productIdentifiers: productIds})
      androidOfferings.value = offerings.products
      var productsList = offerings.products
      console.log('productsList initial', JSON.stringify(productsList))
      monthly.value = productsList.filter(product => product.identifier === "google_7_day_free_999_monthly:7-day-free-999-monthly")[0]
      annual.value = productsList.filter(product => product.identifier === "google_7_day_free_9999_annual:7-day-free-annual-9999")[0]
      console.log('productsList 6 monthly', JSON.stringify(monthly.value))
      console.log('productsList 6 annual', JSON.stringify(annual.value))
      products.value = productsList
      return true
    } catch (error) {
      console.log('productsList error', error)
      errorStr.value = error
      return false
    }
  }

  async function purchase(product) {
    console.log('attempt purchase', product)
    isLoading.value = true
    try {
      const purchaseResult = await Purchases.purchaseStoreProduct({ product: toRaw(product) })
      console.log('purchaseResult was ', JSON.stringify(purchaseResult))
      if (purchaseResult.transaction && purchaseResult.transaction.transactionIdentifier) {
        console.log('transactionIdentifier', purchaseResult.transaction.transactionIdentifier)
        console.log('transaction', JSON.stringify(purchaseResult.transaction.transactionIdentifier))
        console.log('productId', purchaseResult.transaction.productId)
        console.log('startTime', purchaseResult.transaction.productId)
        const response = await fetchWrapper.post(`/android/create-subscription/`, {
          transactionIdentifier: purchaseResult.transaction.transactionIdentifier,
          productId: purchaseResult.transaction.productIdentifier,
          startTime: purchaseResult.transaction.purchaseDateMillis,
          expiresTime: purchaseResult.customerInfo.latestExpirationDateMillis,
          price: product.price,
          currency: product.currencyCode
        })
        console.log('android/create-subscription response', response)
        if (props.sendCompletion) {
          console.log('android.billing sendCompletion is on, sending a success')
          emit('sendCompleted', 'success')
          isLoading.value = false
          return
        }
        isLoading.value = false
      } else {
        console.log('android.billing could not find transactionIdentifier')
      }
      await loadBillingInfo()
    } catch (error) {
      console.log("android.billing ERROR from purchase attempt", error)
      if (props.sendCompletion) {
        console.log('android.billing in error case, going to emit', error)
        emit('sendCompleted', error)
      } else {
        errorStr.value = error.code
      }
      isLoading.value = false
    }
  }

  async function loadBillingInfo() {
    isLoading.value = true
    errorStr.value = false
    currentProduct.value = false
    currentSubscriptionType.value = false
    console.log("android.billing loadBillingInfo started")

    try {
      if (!await updateProducts()) {
        console.log('android.billing return false, probably no store account attached.  bailing out.')
        isLoading.value = false
        storeError.value = errorStr.value
        errorStr.value = false
        return
      }
      console.log("android.billing loadBillingInfo updateProducts returned")
    } catch (error) {
      console.log('android.billing error on updateProducts', error)
    }
    try {
      console.log('android.billing about to enter getCustomerInfo')
      const customerInfo = await Purchases.getCustomerInfo()
      console.log('android.billing customerInfo returned from RevenueCat', JSON.stringify(customerInfo))
      customer.value = customerInfo
      var activeSubscriptions = customerInfo.customerInfo.activeSubscriptions
      console.log('activeSubscriptions is', JSON.stringify(activeSubscriptions))
      if (activeSubscriptions.length === 0) {
        showPricingPlans.value = true
      } else {
        showPricingPlans.value = false
        // console.log('activeSubscriptions', customerInfo.customerInfo.activeSubscriptions[0])
        currentProduct.value = products.value.filter(product => product.identifier === customerInfo.customerInfo.activeSubscriptions[0])[0]
        // or is it storeProductId ??
        console.log('currentProduct.value', JSON.stringify(currentProduct.value))
        try {
          console.log('look for currentSubscriptionType', customerInfo)
          currentSubscriptionType.value = customerInfo.customerInfo.entitlements.active["Full Access Android"].periodType 
          console.log('set currentSubscriptionType', currentSubscriptionType.value)
        }
        catch (error) {
          console.log('error on currentSubscriptionType', error)
        }
        console.log('currentProduct.value', currentProduct.value)
        emit('sendCompleted', 'success')
      }
    } catch (error) {
      console.log('android.billing error on getCustomerInfo', error)
    }
    isLoading.value = false
  }

  onMounted(async () => {
    setTimeout( async function () {
      await loadBillingInfo()
    }, 1000)
  })



</script>

<template>
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
  <section class="my-5 billingMain">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="isLoading === false" class="bg-white shadow border-0 rounded border-light p-4 p-md-5 w-100 fmxw-500">
            <div v-if="mode !== 'registration'" class="text-center text-md-center mb-4 mt-md-0">
              <h1 class="mb-0 h1-title pb-3">{{ t('navbar.billing') }}</h1>
              <hr />
            </div>
            <template v-if="isAndroidApp">
              <div v-if="storeError" class="col-lg-6">
                <div class="plan pb-2">
                  <div class="plan-head">
                    <h3 class="h3-title-lg fnt-weight-700 mb-3">{{ t('billing.store-error') }}</h3>
                  </div>
                  <div class="plan-body my-3">
                    <div>
                      <ul class="p-0 py-1">
                        <li class="alert alert-warning">
                          {{ t('billing.please-ensure-you-are-logged-into-your-play') }}
                        </li>
                      </ul>
                    </div>
                    <div class="plan-foot">
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="showPricingPlans === false" class="row my-5 pb-2 text-start row-gap-4 currentplan">
                <h3 class="h2-title text-start">{{ t('billing.current-plan') }}</h3>
                <div class="col-md-6">
                  <label for="processor">{{ t('billing.payment-processor') }}</label>
                  <input type="text" class="form-control form-control-lg" id="processor" readonly value="Android" />
                </div>
                <div v-if="currentSubscriptionType === 'TRIAL'" class="col-md-6">
                  <label for="plan">{{ t('billing.current-subscription') }}</label>
                  <input type="text" class="form-control form-control-lg" id="plan" readonly value="Free Trial" />
                </div>
                <div v-else class="col-md-6">
                  <label for="plan">{{ t('billing.current-subscription') }}</label>
                  <input type="text" class="form-control form-control-lg" id="plan" readonly :value="'$' + currentProduct.price + ' ' + currentProduct.currencyCode" />
                </div>
                <div class="col-md-6">
                  <label for="last">{{ t('billing.started') }}</label>
                  <input type="text" class="form-control form-control-lg" id="last" readonly :value="standardizeTime(customer.customerInfo.allPurchaseDatesMillis[customer.customerInfo.activeSubscriptions[0]])" />
                </div>
                <div class="col-md-6">
                  <label for="expires">{{ t('billing.expires') }}</label>
                  <input type="text" class="form-control form-control-lg" id="expires" readonly :value="standardizeTime(customer.customerInfo.allExpirationDatesMillis[customer.customerInfo.activeSubscriptions[0]])" />
                </div>
                <hr />
                <div class="col-md-12">
                  <div class="alert alert-success" role="alert">
                    <p><b>{{ t('billing.manage-your-subscription') }}</b><br>{{ t('billing.please-manage-your-subscription-through-the-google-play') }}</p>
                  </div>
                </div>
              </div>
              <div v-if="errorStr" class="alert alert-danger" role="alert">
                {{ errorStr }}
              </div>
              <div id="pricing-plans" v-else-if="showPricingPlans === true" class="row pricing-plans-small row-gap-4 justify-content-center">
                <!-- start monthly -->
                <div class="col-lg-6">
                  <div class="plan pb-2">
                    <div class="plan-head">
                      <h3 class="h3-title-lg fnt-weight-700 mb-3">${{ monthly.price }} {{ monthly.currencyCode }} <span>/ {{ t('billing.month') }}</span></h3>
                      <h4 class="fnt-20 fnt-weight-700 my-2">{{ t('billing.monthly-pro-plan') }}</h4>
                    </div>
                    <div class="plan-body my-3">
                      <ul class="p-0 py-1">
                        <li class="alert alert-success mb-1">{{ t('register.this-is-a-full-access-trial-and-you') }}</li>
                        <li>{{ t('register.7-day-no-risk-free-trial') }}</li>
                        <li>{{ t('home.unlimited-access') }}</li>
                        <li>{{ t('home.unlimited-practice-sessions') }}</li>
                        <li>{{ t('home.immersive-roleplays-&-modes') }}</li>
                        <li>{{ t('home.personalized-lessons') }}</li>
                        <li>{{ t('home.personalized-learning') }}</li>
                        <li>{{ t('home.advanced-voices') }}</li>
                        <li><del>{{ t('home.annual-discount') }}</del></li>
                      </ul>
                    </div>
                    <div class="plan-foot">
                      <button type="button" class="btn btn-primary-sm btn-primary-cs text-decoration-none mb-1" @click="purchase(monthly)">{{ t('billing.start-monthly-subscription') }}</button>
                    </div>
                  </div>
                </div>
                <!-- end monthly -->
                <!-- start annual -->
                <div class="col-lg-6">
                  <div class="plan pb-2">
                    <div class="plan-head">
                      <h3 class="h3-title-lg fnt-weight-700 mb-3">${{ annual.price }} {{ annual.currencyCode }} <span>/ {{ t('home.year') }}</span></h3>
                      <h4 class="fnt-20 fnt-weight-700 my-2">{{ t('home.dedicated-annual-learner') }}</h4>
                    </div>
                    <div class="plan-body my-3">
                        <ul class="p-0 py-1">
                          <li class="alert alert-success">{{ t('register.this-is-a-full-access-trial-and-you') }}</li>
                          <li>{{ t('register.7-day-no-risk-free-trial') }}</li>
                          <li>{{ t('home.unlimited-access') }}</li>
                          <li>{{ t('home.unlimited-practice-sessions') }}</li>
                          <li>{{ t('home.immersive-roleplays-&-modes') }}</li>
                          <li>{{ t('home.personalized-lessons') }}</li>
                          <li>{{ t('home.personalized-learning') }}</li>
                          <li>{{ t('home.advanced-voices') }}</li>
                          <li><b>{{ t('home.annual-discount') }}!</b></li>
                        </ul>
                      </div>
                      <div class="plan-foot">
                        <button type="button" class="btn btn-primary-sm btn-primary-cs text-decoration-none mb-1" @click="purchase(annual)">{{ t('billing.start-annual-subscription') }}</button>
                      </div>
                    </div>
                </div>
                <!-- end annual -->
              </div>
              <hr>
              <div v-if="errorStr" class="alert alert-danger" role="alert">
                 {{ errorStr }}
              </div> 
              <button v-if="mode === 'tab'" class="btn btn-grp btn-primary-grp" @click="loadBillingInfo">{{ t('billing.refresh-billing-info') }}</button>
            </template>
            <template v-else>
              <div class="row my-5 pb-4 text-start row-gap-4 currentplan">
								<h3 class="h2-title text-start">{{ t('billing.current-plan') }}</h3>
								<div class="col-md-12">
                  <div class="alert alert-success" role="alert">
                    <p><b>{{ t('billing.please-note') }}: </b>{{ t('billing.your-subscription-is-managed-through-the-android-app') }}</p>
                  </div>
                </div>
								<div class="col-md-6">
									<label for="processor">{{ t('billing.payment-processor') }}</label>
									<input type="text" class="form-control form-control-lg" id="processor" readonly :value="billing.current.processor" />
								</div>
								<div class="col-md-6">
									<label for="duration">{{ t('billing.account-type') }}</label>
									<input type="text" class="form-control form-control-lg" id="duration" readonly :value="capitalizeFirstLetter(billing.current.duration)" />
								</div>
								<div class="col-md-6">
									<label for="processor">{{ t('billing.start-date') }}</label>
									<input type="text" class="form-control form-control-lg" id="start_date" readonly :value="standardizeDate(billing.current.start_date)" />
								</div>
								<div class="col-md-6">
                  <label for="processor">{{ t('billing.end-date') }}</label>
									<input type="text" class="form-control form-control-lg" id="start_date" readonly :value="standardizeDate(billing.current.end_date)" />
								</div>
								<div class="col-md-6">
                  <label for="status">{{ t('billing.status') }}</label>
									<input type="text" class="form-control form-control-lg" id="status" readonly :value="billing.current.status" />
								</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .modal-backdrop {
     z-index: 20000;
   }
   .modal {
     z-index: 20001;
   }
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>


