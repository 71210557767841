<script setup>
  import { ref, onMounted, defineProps } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useI18n } from 'vue-i18n'
  import { capitalizeFirstLetter, standardizeDate } from '@/helpers/utils.js'
  import { env } from '@/helpers/env.js'
  const baseEnv = env
  const { t } = useI18n()
  const authStore = ref(false)
  authStore.value = useAuthStore()
  const isLoading = ref(false)
  // const stripeKey = "pk_test_51PYvyXRx7SpTqnitJvtWmdanBIOK9y5Ny0OXHgDwy5UXzImiRh9zcCwgY73aAkpFtAG7zPNF5tLl6vMpF0jqEFoY00RUwJjeMY"
  const stripeKey = "pk_live_51PYvyXRx7SpTqnitbK4RXMxncAxt7LzD2lr0jtMpY7qFjRbsn03XSU9IZUguQKTUwnQ63jZf7q0buE6X6ub27GwC00Ugj49MdP"
  const stripe = ref(false)
  const showStripeForm = ref(false)
  const showPricingPlans = ref(false)
  const stripePaymentError = ref(false)
  const showHistory = ref(true)
  const cancelReason = ref('')
  const cancelDetails = ref('')
  const pricingDetails = ref({})
  const showCancelSubscriptionModal = ref(false)
  const paymentElementContainer = ref(false)
  import { loadStripe } from '@stripe/stripe-js';

  const props = defineProps({
    billingInfo: Object
  })
  const billing = ref(props.billingInfo)

  async function loadBilling(loadRemote) {
    if (loadRemote === true) {
      const response = await fetchWrapper.get(`/billing/`)
      console.log(response)
      billing.value = response
    }
    isLoading.value = false
    if (billing.value.current.duration === 'trial') {
      showPricingPlans.value = true
    } else if (!billing.value.current.will_renew) {
      showPricingPlans.value = true
    }
  }
  async function createSubscription(duration) {
    console.log('create subscription')
    showPricingPlans.value = false
    showStripeForm.value = true
    const response = await fetchWrapper.post(`/stripe/create-subscription/`, { duration: duration })
    pricingDetails.value = response
    console.log('stripe createSubscription ', response)
    var appearance = {
      'theme': 'stripe'
    }
    const options = {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: false,
        spacedAccordionItems: true
      }
    }
    var elements = stripe.value.elements({clientSecret: response.clientSecret, appearance: appearance})
    const paymentElement = elements.create('payment', options)
    paymentElement.mount('#payment-element')
    showPricingPlans.value = false
    showHistory.value = false
    setTimeout(() => {
      const form = document.getElementById('payment-form');

      form.addEventListener('submit', async (event) => {
        event.preventDefault();

        const {error} = await stripe.value.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: `${baseEnv.VUE_APP_WEB_BASE_URL}/billing/success/`,
          },
          redirect: "if_required"
        }).then(function(result) {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            stripePaymentError.value = result.error.message;
            console.log(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              isLoading.value = true
              showPricingPlans.value = false
              showStripeForm.value = false
              paymentElementContainer.value.innerHtml = ''
              showHistory.value = true
              setTimeout(() => {
                console.log('after timeout, loading billing')
                loadBilling(true)
              }, 3000)
            }
          }
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          const messageContainer = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      });
    }, 3000)
  }
  onMounted(async () => {
    await loadBilling(false)
    stripe.value = await loadStripe(stripeKey)
    isLoading.value = false
    console.log('stripe we have a ', stripe)
  })

  async function handleCancellation() {
    console.log('handleCancellation')
    const response = await fetchWrapper.post(`/billing/cancel-subscription/`, { feedback: cancelReason.value, comments: cancelDetails.value })
    console.log('stripe cancelSubscription ', response)
    showCancelSubscriptionModal.value = false
    await loadBilling(true)
  }

</script>

<template>
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
  <section class="my-5 billingMain">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="isLoading === false" class="bg-white shadow border-0 rounded border-light p-4 p-md-5 w-100 fmxw-500">
            <div class="text-center text-md-center mb-4 mt-md-0">
              <h1 class="mb-0 h1-title pb-3">{{ t('navbar.billing') }}</h1>
              <hr />
            </div>
            <div v-if="showHistory" class="row my-5 pb-4 text-start row-gap-4 currentplan">
              <h3 class="h2-title text-start">{{ t('billing.current-plan') }}</h3>
              <div class="col-md-6">
                <label for="processor">{{ t('billing.payment-processor') }}</label>
                <input type="text" class="form-control form-control-lg" id="processor" readonly :value="billing.current.processor" />
              </div>
              <div class="col-md-6">
                <label for="duration">{{ t('billing.account-type') }}</label>
                <input type="text" class="form-control form-control-lg" id="duration" readonly :value="capitalizeFirstLetter(billing.current.duration)" />
              </div>
              <div class="col-md-6">
                <label for="processor">{{ t('billing.start-date') }}</label>
                <input type="text" class="form-control form-control-lg" id="start_date" readonly :value="standardizeDate(billing.current.start_date)" />
              </div>
              <div class="col-md-6">
                <label for="processor">{{ t('billing.end-date') }}</label>
                <input type="text" class="form-control form-control-lg" id="start_date" readonly :value="standardizeDate(billing.current.end_date)" />
              </div>
              <div class="col-md-6">
                <label for="status">{{ t('billing.status') }}</label>
                <input type="text" class="form-control form-control-lg" id="status" readonly :value="billing.current.status" />
              </div>
            </div>
            <div v-if="!showPricingPlans" class="row justify-content-center">
              <div class="col-md-6 col-xs-6">
                <button v-if="billing.current.will_renew" type="button" class="btn btn-primary-cs text-decoration-none mb-4 text-white" @click="showCancelSubscriptionModal = true">
                  {{ t('billing.cancel-subscription') }}
                </button>
              </div>
            </div>
            <div id="pricing-plans" v-if="showPricingPlans" class="row pricing-plans row-gap-4 justify-content-center">
              <!-- start monthly -->
              <div class="col-lg-6">
                <div class="plan pb-4">
                  <div class="plan-head">
                    <h3 class="h3-title-lg fnt-weight-700 mb-3">$9.99 USD <span>/ {{ t('billing.month') }}</span></h3>
                    <h4 class="fnt-20 fnt-weight-700 my-2">{{ t('billing.monthly-pro-plan') }}</h4>
                  </div>
                  <div class="plan-body my-3">
                    <ul class="p-0 py-3">
                      <li>{{ t('home.unlimited-access') }}</li>
                      <li>{{ t('home.unlimited-practice-sessions') }}</li>
                      <li>{{ t('home.immersive-roleplays-&-modes') }}</li>
                      <li>{{ t('home.personalized-lessons') }}</li>
                      <li>{{ t('home.personalized-learning') }}</li>
                      <li>{{ t('home.advanced-voices') }}</li>
                      <li><del>{{ t('home.annual-discount') }}</del></li>
                    </ul>
                  </div>
                  <div class="plan-foot">
                    <button type="button" class="btn btn-primary-lg btn-primary-cs text-decoration-none mb-4" @click="createSubscription('monthly')">{{ t('billing.start-monthly-subscription') }}</button>
                  </div>
                </div>
              </div>
              <!-- end monthly -->
              <!-- start annual -->
              <div class="col-lg-6">
                <div class="plan pb-4">
                  <div class="plan-head">
                    <h3 class="h3-title-lg fnt-weight-700 mb-3">$99.99 USD <span>/ {{ t('home.year') }}</span></h3>
                    <h4 class="fnt-20 fnt-weight-700 my-2">{{ t('home.dedicated-annual-learner') }}</h4>
                  </div>
                  <div class="plan-body my-3">
                      <ul class="p-0 py-3">
                        <li>{{ t('home.unlimited-access') }}</li>
                        <li>{{ t('home.unlimited-practice-sessions') }}</li>
                        <li>{{ t('home.immersive-roleplays-&-modes') }}</li>
                        <li>{{ t('home.personalized-lessons') }}</li>
                        <li>{{ t('home.personalized-learning') }}</li>
                        <li>{{ t('home.advanced-voices') }}</li>
                        <li><b>{{ t('home.annual-discount') }}!</b></li>
                      </ul>
                    </div>
                    <div class="plan-foot">
                      <button type="button" class="btn btn-primary-lg btn-primary-cs text-decoration-none mb-4" @click="createSubscription('annual')">{{ t('billing.start-annual-subscription') }}</button>
                    </div>
                  </div>
              </div>
              <!-- end annual -->
            </div>
            <div v-if="showHistory" class="row my-5 pb-5 text-start row-gap-4 previousInvoices">
              <h3 class="h2-title text-start">{{ t('billing.previous-transactions') }}</h3>
              <div class="col-12 d-none d-lg-block">
                <CContainer>
                  <CRow>
                    <CCol>
                      <CCard>
                        <CCardHeader>
                          <CRow>
                            <CCol class="col-lg-4 text-center">{{ t('billing.invoice') }} #</CCol>
                            <CCol class="col-lg-2 text-center">{{ t('billing.date') }}</CCol>
                            <CCol class="col-lg-2 text-center">{{ t('billing.amount') }}</CCol>
                            <CCol class="col-lg-2 text-center">{{ t('billing.status') }}</CCol>
                          </CRow>
                        </CCardHeader>
                        <CCardBody>
                          <CRow v-for="historical in billing.historical" v-bind:key="historical.payment_id">
                          <CCol class="col-lg-4 text-center"><small>{{ historical.payment_id }}</small></CCol>
                            <CCol class="col-lg-2 text-center">{{ standardizeDate(historical.start_date) }}</CCol>
                            <CCol class="col-lg-2 text-center" v-if="historical.amount == 'free'">
                              $ 0.00 
                            </CCol>
                            <CCol class="col-lg-2 text-center" v-else>
                              $ {{ historical.amount }} {{ historical.currency }}
                            </CCol>
                            <CCol class="col-lg-2 text-center">{{ historical.payment_status }}</CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CContainer>
              </div>
              <div class="col-12 d-lg-none d-xl-none d-xxl-none">
                <CContainer v-for="historical in billing.historical" v-bind:key="historical.payment_id" class="mb-3">
                  <CRow>
                    <CCol>
                      <CCard>
                        <CCardHeader>
                          <CRow>
                            <CCol class="col-lg-4">{{ t('billing.invoice') }}</CCol>
                            <CCol class="col-lg-4">{{ historical.payment_id }}</CCol>
                          </CRow>
                        </CCardHeader>
                        <CCardBody>
                          <CRow>
                            <CCol class="col-lg-2">{{ t('billing.date') }}</CCol>
                            <CCol class="col-lg-2">{{ standardizeDate(historical.start_date) }}</CCol>
                          </CRow>
                          <CRow>
                            <CCol class="col-lg-2">{{ t('billing.amount') }}</CCol>
                            <CCol class="col-lg-2" v-if="historical.amount == 'free'">
                              $ 0.00 
                            </CCol>
                            <CCol class="col-lg-2" v-else>
                              $ {{ historical.amount }} {{ historical.currency }}
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol class="col-lg-2">{{ t('billing.payment-status') }}</CCol>
                            <CCol class="col-lg-2">{{ historical.payment_status }}</CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CContainer>
              </div>
            </div>
            <div v-else class="row">
            </div>
            <div class="row pricing-plan mt-5" v-if="showStripeForm">
              <div class="col-lg-6 text-start">
                <div class="plan pb-4">
                  <div class="plan-head">
                    <!-- <h4 class="fnt-20 fnt-weight-700 my-2">Credit Card Processing with Stripe</h4> -->
                    <p  class="fnt-weight-600">{{ pricingDetails.description }}</p>
                  </div>
                  <div class="plan-body my-5">
                    <div class="planPrice">
                      <div class="d-flex justify-content-between">
                        <span class="fnt-weight-600">{{ t('billing.sub-total') }}: </span>
                        <span class="fnt-weight-600">${{ pricingDetails.subtotal_excluding_tax }}</span>
                      </div>
                      <hr>
                      <div class="d-flex justify-content-between">
                        <span class="fnt-weight-700 fnt-20">{{ t('billing.total') }}: </span>
                        <span class="fnt-weight-700 fnt-20">${{ pricingDetails.total}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <form id="payment-form">
                  <div id="payment-element" ref="paymentElementContainer"></div>
                  <div id="error-message"></div>
                  <div v-if="stripePaymentError">{{ stripePaymentError }}</div>
                  <button id="submit" class="btn btn-primary-lg btn-primary-cs text-decoration-none mb-4 mt-5">{{ t('billing.subscribe') }}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Cancel Subscription Modal -->
  <CModal :visible="showCancelSubscriptionModal" @close="() => { showCancelSubscriptionModal = false }" class="cancelSubscriptionModal">
    <form @submit.prevent="handleCancellation">
      <CModalHeader>
        <CModalTitle>{{ t('billing.cancel-your-subscription') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div class="row text-start my-3">
          <p><b>{{ t('billing.were-sorry-to-see-you-go--please-tell') }}:</b></p>
          <div class="col-12 my-3">
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" name="cancelReason" id="reason1" value="customer_service" v-model="cancelReason" required>
              <label class="form-check-label" for="reason1">{{ t('billing.customer-service-was-less-than-expected') }}</label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" name="cancelReason" id="reason2" value="low_quality" v-model="cancelReason">
              <label class="form-check-label" for="reason2">{{ t('billing.quality-was-less-than-expected') }}</label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" name="cancelReason" id="reason3" value="missing_features" v-model="cancelReason">
              <label class="form-check-label" for="reason3">{{ t('billing.some-features-are-missing') }}</label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" name="cancelReason" id="reason4" value="switched_service" v-model="cancelReason">
              <label class="form-check-label" for="reason4">{{ t('billing.i’m-switching-to-a-different-service') }}</label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" name="cancelReason" id="reason5" value="too_complex" v-model="cancelReason">
              <label class="form-check-label" for="reason5">{{ t('billing.ease-of-use-was-less-than-expected') }}</label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" name="cancelReason" id="reason6" value="too_expensive" v-model="cancelReason">
              <label class="form-check-label" for="reason6">{{ t('billing.it’s-too-expensive') }}</label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" name="cancelReason" id="reason7" value="unused" v-model="cancelReason">
              <label class="form-check-label" for="reason7">{{ t('billing.i-don’t-use-the-service-enough') }}</label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" name="cancelReason" id="reason8" value="other" v-model="cancelReason">
              <label class="form-check-label" for="reason8">{{ t('billing.other-reason') }}</label>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label for="cancelDetails" class="form-label">{{ t('billing.if-youd-like-please-provide-more-details-about') }}:</label>
            <textarea class="form-control" id="cancelDetails" rows="3" v-model="cancelDetails"></textarea>
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton type="button" color="secondary" @click="() => { showCancelSubscriptionModal = false }">
          {{ t('billing.keep-my-subscription') }}
        </CButton>
        <CButton type="submit" color="danger">
          {{ t('billing.confirm-cancellation') }}
        </CButton>
      </CModalFooter>
    </form>
  </CModal>
  <!-- Cancel Subscription Modal -->
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .modal-backdrop {
     z-index: 20000;
   }
   .modal {
     z-index: 20001;
   }
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>


