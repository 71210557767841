<script setup>
import { ref, onMounted } from 'vue'
const email = ref('')
const firstName = ref('')
const lastName = ref('')
const password = ref('')
const password2 = ref('')
const nativeLanguage = ref('')
const learnLanguage = ref('')
const learnLevel = ref('')
const emailErrors = ref([])
const passwordErrors = ref([])
const password2Errors = ref([])
const languageOptions = ref([])
const languageOptionsEnglish = ref([])
const emailDone = ref(false)
const emailValidation = ref(false)
const emailValidationErrors = ref(false)
const emailValidationSuccess = ref(false)
const passwordValidationErrors = ref(false)
const passwordValidationSuccess = ref(false)
import { useAuthStore } from '@/stores/auth.store'
import { useI18n } from 'vue-i18n'
import { storeToRefs} from 'pinia'
const isLoaded = ref(false)
const {supportedLanguages, flags} = storeToRefs(useAuthStore())
const { t } = useI18n()
import { useRoute } from 'vue-router'
const $route = useRoute()
import { IonContent, IonPage } from '@ionic/vue'

const authStore = useAuthStore()

const handleIconClick = (node) => {
  node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
  node.props.type = node.props.type === 'password' ? 'text' : 'password'
}

onMounted(async () => {
  if ($route.params.validation_token) {
    const validationUser = $route.params.validation_user
    const validationToken = $route.params.validation_token
    emailValidation.value = true
    console.log('attempt to validate', validationToken)
    authStore.returnUrl = '/dashboard'
    const response = await authStore.validateEmail(validationUser, validationToken).catch(
      function (error) { 
        console.log('registerError');
        console.log(error);
        emailValidationErrors.value = error
      }
    ).then(() => {
      if (emailValidationErrors.value === false) {
        emailValidationSuccess.value = "Your email has been validated."
      }
    })
    console.log('validationToken says', response)
  }
  // iterate supportedLanguages and create an array of objects for the select options.  Look up the "label" value from the flags object
  for (const [key, value] of Object.entries(supportedLanguages.value)) {
    languageOptions.value.push({ value: key, label: flags.value[value].display })
    languageOptionsEnglish.value.push({ value: key, label: flags.value[value].display + " (" + key + ")"})
  }
  // populate the nativeLanguage value from local storage if it exists
  if (localStorage.getItem('language')) {
    for (const key in supportedLanguages.value) {
      if (supportedLanguages.value[key] === localStorage.getItem('language')) {
        nativeLanguage.value = key
      }
    }
  }
  isLoaded.value = true
})

async function checkValidation (currentStep) {
  if (emailDone.value === true) {
    return true
  }
  emailErrors.value = []
  if (currentStep.id !== 'contactInformation') {
    return true
  }
  const languageCode = localStorage.getItem('language')
  const result = await authStore.register(email.value, languageCode).catch( 
    function (error) { 
      console.log('registerError');
      console.log(error);
      emailErrors.value = [error]
    }
  ).then(() => {
    console.log('register success')
  })
  if (emailErrors.value.length > 0) {
    console.log('received error', result, 'blocking return')
    return false
  }
  emailDone.value = true
  return true
}

function submitHandler() {
  console.log('submitHandler')
  authStore.returnUrl = '/dashboard'
  authStore.updateRegistration(email.value, nativeLanguage.value, learnLanguage.value, learnLevel.value, firstName.value, lastName.value) 
}

async function checkPasswordStrength(password) {
  // check if password is a good strength password, if not, set a message in passwordValidationErrors
  if (password.length < 8) {
    passwordValidationErrors.value = "Password must be at least 8 characters long."
  }
  // check if password contains multiple character types
  let characterTypes = 0
  if (password.match(/[a-z]/)) {
    characterTypes++
  }
  if (password.match(/[A-Z]/)) {
    characterTypes++
  }
  if (password.match(/[0-9]/)) {
    characterTypes++
  }
  if (password.match(/[^a-zA-Z0-9]/)) {
    characterTypes++
  }
  if (characterTypes < 3) {
    passwordValidationErrors.value = "Password must contain at least 3 of the following: lowercase letter, uppercase letter, number, special character."
    return false
  }
  return true
}

async function submitPasswordHandler() {
  // check if password.value and password2.value are good strength passwords, if not, set a message in passwordValidationErrors
  const strength = await checkPasswordStrength(password.value)
  if (!strength) {
    return 
  }
  passwordValidationErrors.value = false
  const validationUser = $route.params.validation_user
  const validationToken = $route.params.validation_token
  emailValidationSuccess.value = false
  await authStore.validateEmail(validationUser, validationToken, password.value, password2.value).catch(
    function (error) { 
      console.log('registerError');
      console.log(error);
      passwordValidationErrors.value = error
    }
  ).then(() => {
    if (passwordValidationErrors.value === false) {
      passwordValidationSuccess.value = "Your email has been validated."
    }
  })
}

</script>

<template>
  <ion-page>
    <ion-content>
      <div>
        <site-header :needsReload=true />
        <div class="row justify-content-center">
          <div class="col-xl-6 col-sm-8">
            <h4>{{ t('register.please-choose-your-password') }}</h4>
          </div>
        </div>
        <div v-if="emailValidation" class="row justify-content-center">
          <div v-if="isLoaded" id="validationDiv" class="col-xl-6 col-sm-8 align-self-center">
            <div v-if="emailValidationErrors" class="alert alert-danger" role="alert">
              <template v-if="emailValidationErrors">
                {{ emailValidationErrors }}
              </template>
            </div>
            <template v-else-if="emailValidationSuccess">
              <div v-if="passwordValidationErrors" class="alert alert-danger" role="alert">
                {{ passwordValidationErrors }}
              </div>
              <FormKit type="form" @submit="submitPasswordHandler" form-class="outer-container">
                <FormKit 
                  name="password"
                  type="password" 
                  :label="t('register.your-password')" 
                  v-model="password"
                  :errors="passwordErrors"
                  validation="required|password" 
                  suffix-icon="eyeClosed"
                  @suffix-icon-click="handleIconClick"
                  suffix-icon-class="hover:text-blue-500"
                />
                <FormKit 
                  name="password_confirm"
                  type="password" 
                  :label="t('register.your-password-again')" 
                  v-model="password2"
                  :errors="password2Errors"
                  validation="required|confirm" 
                  suffix-icon="eyeClosed"
                  @suffix-icon-click="handleIconClick"
                  suffix-icon-class="hover:text-blue-500"
                />
              </FormKit>
            </template>
            <div v-else-if="passwordValidationSuccess">
              <div class="alert alert-success" role="alert">
                {{ passwordValidationSuccess }}
              </div>
              <div v-if="authStore.user">
                <router-link to="/dashboard" class="btn btn-primary">{{ t('register.continue-to-dashboard') }}</router-link>
              </div>
              <div v-else>
                <router-link to="/login" class="btn btn-primary">{{ t('register.login-to-continue') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<style scoped>
.outer-container {
  border: 1px solid #e4e4e4;
  padding: 1.5em;
  border-radius: 1em;
  margin: 0 auto 1em auto;
  align-self: center;
  align-items: center;
}

.formkit-outer {
  margin: 0 auto 1em auto;
}

.input-group {
  display: flex;
  gap: 1em;
}

.formkit-outer[data-type="multi-step"] > .formkit-wrapper {
  margin: 0 auto 1em auto;
}
.formkit-actions > .formkit-outer > div.formkit-wrapper {
  margin: 0 auto 1em auto;
}

#validationDiv .formkit-outer > .formkit-wrapper {
  margin: 0 auto 1em auto;
}

h1,
h3 {
  text-align: center;
}
</style>
