<script setup>
  import { defineProps } from 'vue'
  const props = defineProps({
    card: Object,
    which: String, // 'front' or 'back'
  });
</script>

<template>
  <template v-if="props.which === 'front'">
    <template v-if="props.card.type === 'native:learning'">
      <span v-html="props.card.front.word"/>
    </template>
    <template v-else-if="props.card.type === 'learning:native'">
      <span v-html="props.card.front.definition"/>
    </template>
    <template v-else-if="props.card.type === 'vocabularyRecall'">
      <span v-html="props.card.front.term"/>
    </template>
    <template v-else-if="props.card.type === 'vocabularyRecallReverse'">
      <span v-html="props.card.front.translation"/>
    </template>
    <template v-else-if="props.card.type === 'grammarRule'">
      <span v-html="props.card.front.question"/>
    </template>
    <template v-else v-for="item in props.card.front">
      <template v-if="item.string">
        <span v-html="item.string" v-bind:key="item.string"></span>
      </template>
    </template>
  </template>
  <template v-else>
    <template v-if="props.card.type === 'native:learning'">
      <span v-html="props.card.front.definition"/>
    </template>
    <template v-else-if="props.card.type === 'learning:native'">
      <span v-html="props.card.front.word"/>
    </template>
    <template v-else-if="props.card.type === 'vocabularyRecall'">
      <span v-html="props.card.front.translation"/>
    </template>
    <template v-else-if="props.card.type === 'vocabularyRecallReverse'">
      <span v-html="props.card.front.term"/>
    </template>
    <template v-else-if="props.card.type === 'grammarRule'">
      <span v-html="props.card.front.answer"/>
    </template>
    <template v-else v-for="item in props.card.back" v-bind:key="item.string">
      <template v-if="item.string">
        <span v-html="item.string"></span>
      </template>
    </template>
  </template>
</template>


<style type='text/css' scoped>
  .pointer {
    cursor: pointer;
  }
  .specialicon {
    all: unset !important;
    width: 16px !important;
    height: 16px !important;
  }
  
</style>
