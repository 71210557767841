<script setup>
  import { ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useCookieStore } from '@/stores/cookie.store'
  const { t } = useI18n()
  const showManageCookieModal = ref(false)
  const loaded = ref(false)
  const cookieStore = useCookieStore()
  const trackingAllowed = ref(cookieStore.trackingAllowed)

  function showModal () {
    showManageCookieModal.value = true
  }
  function updateTracking () {
    cookieStore.setTracking(trackingAllowed.value)
  }
  function acceptAllCookies() {
    cookieStore.acceptAllCookies()
    trackingAllowed.value = cookieStore.trackingAllowed
    cookieStore.markDisclaimerAsShown()
    showManageCookieModal.value = false
  }
  function saveSettings() {
    cookieStore.markDisclaimerAsShown()
    showManageCookieModal.value = false
  }
  loaded.value = true
</script>

<template>
  <CModal :visible="showManageCookieModal"  class="cancelSubscriptionModal" @close="() => { showManageCookieModal = false }">
    <form @submit.prevent="handleCancellation">
      <CModalHeader>
        <CModalTitle>{{ t('home.cookies') }}</CModalTitle>
      </CModalHeader>
      <CModalBody v-if="loaded">
        <CAccordion>
        <CAccordionItem :item-key="1">
            <CAccordionHeader>
              {{ t('home.manage-your-cookie-settings') }}
            </CAccordionHeader>
            <CAccordionBody>
              {{ t('home.lingo-ing-respects-your-privacy--find-out-more-about') }}
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem :item-key="2">
            <CAccordionHeader>
              {{ t('home.targeting-cookies') }}
            </CAccordionHeader>
            <CAccordionBody>
              <div class="form-check form-switch">
                <input type="checkbox" v-model="trackingAllowed" class="form-check-input" id="formSwitchCheck"  @change="updateTracking">
              </div>
              {{ t('home.these-cookies-may-be-placed-on-our-site') }}
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem :item-key="3">
            <CAccordionHeader>
              {{ t('home.strictly-necessary-cookies') }}
            </CAccordionHeader>
            <CAccordionBody>
            <CFormSwitch :label="t('home.always-active-')" id="formSwitchCheckCheckedDisabled" checked disabled/><br />
              {{ t('home.these-cookies-are-essential-for-the-website’s-functionality') }}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CModalBody>
      <CModalFooter>
      <CButton type="button" color="primary" @click="saveSettings">
          {{ t('home.save-cookie-settings') }}
      </CButton>
      <CButton type="button" color="primary" @click="acceptAllCookies">
        {{ t('home.accept-all-cookies') }}
      </CButton>
      </CModalFooter>
    </form>
  </CModal>
  <div class="banner" v-if="cookieStore.shownDisclaimer === false">
    <p>
      {{ t('home.we-use-tracking-cookies-to-analyze-how-you') }}
    </p>
    <button class="form-control" type="button" @click="acceptAllCookies">{{ t('home.accept-cookies') }}</button>
    <button class="form-control" type="button" @click="showModal() ">{{ t('home.manage-cookies') }}</button>
  </div>
</template>

<style scoped>
.banner {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  position: fixed;
  z-index: 2147483645;
  bottom: 0;
  left: 20%;
  width: 80%;
  transform: translate(-10%, -20%);

}
</style>
