<script setup>
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
</script>
<template>
    <div class="row py-70 row-gap-5">
        <div class="col-lg-4">
          <div class="plan pb-4">
            <div class="plan-head">
              <h3 class="h3-title-lg fnt-weight-700 mb-3">{{ t('home.free') }}<span></span></h3>
              <h4 class="fnt-20 fnt-weight-700 my-2">{{ t('home.basic-plan') }}</h4>
              <p class="mb-0">{{ t('home.limited-trial---7-days') }}</p>
            </div>
            <div class="plan-body my-3 m-3">
              <p>{{ t('home.were-so-confident-youll-love-our-product-were') }}</p>
              <ul class="p-0 py-3">
                <li>{{ t('home.free-unrestricted-access') }}</li>
              </ul>
            </div>
            <div class="plan-foot">
              <router-link to="/register/" class="btn btn-primary-lg btn-primary-cs text-decoration-none mb-4">{{ t('home.start-free-trial') }}</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="plan pb-4">
            <div class="plan-head">
              <h3 class="h3-title-lg fnt-weight-700 mb-3">$9.99 USD <span>/ {{ t('home.month') }}</span></h3>
              <h4 class="fnt-20 fnt-weight-700 my-2">{{ t('home.monthly-pro-plan') }}</h4>
              <small>* {{ t('home.price-will-be-converted-to-your-local-currency') }}</small>
            </div>
            <div class="plan-body my-3">
                <ul class="p-0 py-3">
                  <li>{{ t('home.unlimited-access') }}</li>
                  <li>{{ t('home.unlimited-practice-sessions') }}</li>
                  <li>{{ t('home.immersive-roleplays-&-modes') }}</li>
                  <li>{{ t('home.personalized-lessons') }}</li>
                  <li>{{ t('home.personalized-learning') }}</li>
                  <li>{{ t('home.advanced-voices') }}</li>
                  <li><del>{{ t('home.annual-discount') }}</del></li>
                </ul>
              </div>
              <div class="plan-foot">
                <router-link to="/register/" class="btn btn-primary-lg btn-primary-cs text-decoration-none mb-4">{{ t('home.start-free-trial') }}</router-link>
              </div>
            </div>
        </div>
        <div class="col-lg-4">
          <div class="plan pb-4">
            <div class="plan-head">
              <h3 class="h3-title-lg fnt-weight-700 mb-3">$99.99 USD <span>/ {{ t('home.year') }}</span></h3>
              <h4 class="fnt-20 fnt-weight-700 my-2">{{ t('home.dedicated-annual-learner') }}</h4>
              <small>* {{ t('home.price-will-be-converted-to-your-local-currency') }}</small>
            </div>
            <div class="plan-body my-3">
                <ul class="p-0 py-3">
                  <li>{{ t('home.unlimited-access') }}</li>
                  <li>{{ t('home.unlimited-practice-sessions') }}</li>
                  <li>{{ t('home.immersive-roleplays-&-modes') }}</li>
                  <li>{{ t('home.personalized-lessons') }}</li>
                  <li>{{ t('home.personalized-learning') }}</li>
                  <li>{{ t('home.advanced-voices') }}</li>
                  <li><b>{{ t('home.annual-discount') }}!</b></li>
                </ul>
              </div>
              <div class="plan-foot">
                <router-link to="/register/" class="btn btn-primary-lg btn-primary-cs text-decoration-none mb-4">{{ t('home.start-free-trial') }}</router-link>
              </div>
            </div>
        </div>
    </div>
</template>
