<script setup>
import { ref, onMounted } from 'vue'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
const yourEmail = ref('')

import { useI18n } from 'vue-i18n'
import { isApp } from '@/helpers/utils.js'
const isLoading = ref(true)
const { t } = useI18n()
const submitted = ref(false)
import { IonContent, IonPage } from '@ionic/vue'

onMounted(async () => {
  isLoading.value = false
})

async function submitHandler() {
  console.log('submitHandler')
  console.log('yourEmail', yourEmail.value)
  await fetchWrapper.post(`/data-deletion/`, {
    your_email: yourEmail.value,
  })
  submitted.value = true
}

</script>

<template>
  <ion-page>
    <ion-content>
      <site-header v-if="isApp === false" :needsReload="true" />
      <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
      <section class="loginForm my-5 py-4">
        <template v-if="!isLoading">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-sm-8">
              <h1 class="h1-title text-center">{{ t('delete.data-deletion') }}</h1>
            </div>
          </div>
          <div class="row justify-content-center mt-4">
            <div class="col-xl-6 col-sm-8 align-self-center justify-content-center">
              <div v-if="submitted">
                <div class="alert alert-success" role="alert">
                  {{ t('delete.your-data-will-be-deleted-within-24-hours-') }}
                </div>
              </div>
              <FormKit v-else :actions="false" type="form" form-class="row outer-container justify-content-center" outer-classes="p-0">
                <FormKit form-class="multi-container" id="multi-step" type="multi-step" tab-style="progress" :allow-incomplete="false"  outer-class="row justify-content-center">
                  <FormKit :actions="false" id="talkDetails" name="talkDetails" type="form" @submit="submitHandler" >
                    <p class="validationMessageBox">{{ t('delete.request-all-of-my-data-be-deleted') }} </p>
                      <FormKit 
                        name="yourEmail"
                        v-model="yourEmail"
                        type="text" 
                        :label="t('support.your-email')" 
                        :placeholder="t('support.your-email')"
                        validation="required|email"
                      />
                      <FormKit type="submit" :classes="{ input: 'w-100 formkit-step-next btn btn-primary btn-primary-cs' } " />
                  </FormKit>
                </FormKit>
              </FormKit>
            </div>
            <div class="loginForm-signup-link mt-5 pt-5">
              <p>{{ t('register.already-a-member') }}&nbsp;
                <router-link to="/login"> {{ t('register.log-in-here') }}!</router-link>
              </p>
            </div>
          </div>
        </template>
      </section>
      <div class="cityBgBottom"></div>
      <site-footer v-if="isApp === false" />
    </ion-content>
  </ion-page>
</template>
<style scoped>
.formkit-outer {
  margin: 0 auto 1em auto;
}
.input-group {
  display: flex;
  gap: 1em;
}
.formkit-outer[data-type="multi-step"] > .formkit-wrapper {
  margin: 0 auto 1em auto;
}
.formkit-actions > .formkit-outer > div.formkit-wrapper {
  margin: 0 auto 1em auto;
}

#validationDiv .formkit-outer > .formkit-wrapper {
  margin: 0 auto 1em auto;
}
.formkit-outer >>> .formkit-tab-label {
  color: black;
}
div >>> .formkit-tab-label {
  font-size: 0.75em !important;
}
</style>
