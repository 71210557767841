<script setup>
  import { ref } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { IonContent, IonPage } from '@ionic/vue'
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const authStore = ref(false)
  authStore.value = useAuthStore()

</script>

<template>
  <ion-page>
    <ion-content>
      <site-header :needsReload="false" />
      <!-- Hero Section -->
      <section class="hero-section cityBgBottom mt-110 pb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7">
              <h1 class="h1-title mb-4">{{ t('navbar.terms-of-use') }}</h1>
              <p><pre style="white-space: pre-wrap; text-align: left">
Last updated: January 29th, 2024
1. Introduction

Welcome to Lingo.ing, operated by Neural Innovation Lab Ltd. These terms and conditions outline the rules and regulations for the use of Lingo.ing's website and iOS or Android App. By accessing and using Lingo.ing, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Lingo.ing's relationship with you in relation to this website and app. If you disagree with any part of these terms and conditions, do not use our website or app.

2. Ownership

The Lingo.ing website and iOS or Android App, its original content, features, and functionality are and will remain the exclusive property of Lingo.ing. The software is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Lingo.ing. Our software is provided 'as-is', with no warranty of any kind expressed or implied and no event shall our company be liable for any damages including, but not limited to, direct, indirect, special, punitive, incidental, consequential, or any other losses arising from the use of our products.

3. Responsibility

Users of Lingo.ing are responsible for maintaining the confidentiality of their own accounts and passwords, including but not limited to the restriction of access to their computer and/or account. It is your responsibility to take the necessary precautions to protect your own data and your device from threats such as viruses, malware, and other malicious software. You should ensure that you have a reliable antivirus program installed and that it is regularly updated to combat new threats as they emerge.

4. Data Sharing

Your data will be sent to various vendors, including but not limited to OpenAI and OpenRouter, to enhance the functionality of our services. Your data will be used for product improvements, ensuring a better user experience.

5. Payment Processing

Credit card processing will be provided by an external service (currently Stripe) and no credit card information will be stored by Lingo.ing. All transactions will be handled securely by the payment processor.

6. Communication Consent

By using Lingo.ing, you grant us the express permission to email you about your language journey, marketing, and product information. You can opt-out of these communications at any time by following the unsubscribe instructions provided in the emails.

7. Copyright Infringement and DMCA

Lingo.ing respects the intellectual property rights of others and expects its users to do the same. It is Lingo.ing’s policy to respond to any claim that content posted on the website or app infringes the copyright or other intellectual property rights ("Infringement") of any person or entity. Lingo.ing will respond to claims of Infringement in compliance with the Digital Millennium Copyright Act (DMCA) and other applicable laws.

8. Intellectual Property

The website and app and their original content (excluding content provided by users), features, and functionality are and will remain the exclusive property of Lingo.ing and its licensors. The website and app are protected by copyright, trademark, and other laws of the United States and other countries.

9. Changes

Lingo.ing reserves the right, at our sole discretion, to modify or replace these Terms at any time. Your continued use of the website and app constitutes acceptance of these changes. New features and/or services added to future updates or releases are also subject to the terms and conditions of this Agreement.

10. Termination

We may terminate or suspend access to our website and app immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability. If you wish to terminate this agreement, you may simply stop using the website and app to discontinue use. Any clauses in this Agreement that are inherently intended to continue after termination will indeed persist beyond termination. This includes, but is not limited to, sections pertaining to ownership rights, disclaimers of warranty, indemnification obligations, and limitations on liability.

11. Limitation of Liability

In no event shall Lingo.ing, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the website and app; (ii) any conduct or content of any third party on the website and app; (iii) any content obtained from the website and app; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.

12. Representation and Warranty

You represent and warrant that (i) your use of the website and app will be in strict accordance with the Lingo.ing Privacy Policy, with these Terms, and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the country in which you reside) and (ii) your use of the website and app will not infringe or misappropriate the intellectual property rights of any third party.

13. Indemnification

You agree to indemnify and hold harmless Lingo.ing and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the website and app, by you or any person using your account and password; b) a breach of these Terms; or c) content posted on the website and app.

14. Governing Law and Legal Matters

These Terms are governed by and construed in accordance with the laws of British Columbia (BC), Canada, without regard to its conflict of law principles. Any arbitration or other legal matters arising under or relating to these Terms must be handled in a court located in British Columbia (BC), Canada. You consent to the jurisdiction and venue of such courts and waive any objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
                </pre>
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- End -->
      <site-footer />
    </ion-content>
  </ion-page>
</template>

<style scoped>
  ion-content {
		--background: unset !important;
    background-image: url('@/assets/images/lingoing-bg-icons.png');
    background-size: 100%;
    background-repeat: repeat;
  }
	h3 > button {
		color: var(--bs-heading-color) !important;
	}
  #homeview {
    font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
