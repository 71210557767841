<script setup>
import { ref, inject } from 'vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'

import { useAuthStore } from '@/stores/auth.store'
import { changeLocale } from '@formkit/vue'
import { useI18n } from 'vue-i18n'
import { IonContent, IonPage } from '@ionic/vue'
import { isApp } from '@/helpers/utils.js'
import { useLoading } from 'vue-loading-overlay'
const config = inject(Symbol.for('FormKitConfig'))
const { t, locale } = useI18n()
const loginDisplay = ref('none')
const loadingContainer = ref(null)
// this is to put high popularity languages at the top of the list, but we fill in all the others later
const languages = ref(['en', 'es', 'fr', 'ja', 'pl', 'zh', 'it', 'ko'])
const hidden = ref(true)
if (!isApp) {
  hidden.value = false
} else {
  hidden.value = true
}

import supportedLanguages from "../locales/languages.json"
import flags from '../locales/flags.json'
import { loadLocaleMessages } from '../i18n'
const i18n = inject('i18n')

import { onMounted } from 'vue'

const schema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required')
})

const $loading = useLoading({
})

for (const key in supportedLanguages) {
  // if supportedLanguages[key] is already in the languages array, don't add it again
  if (languages.value.includes(supportedLanguages[key])) {
    continue
  }
  languages.value.push(supportedLanguages[key])
}

function onSubmit(values, { setErrors }) {
  const authStore = useAuthStore()
  const { username, password } = values
  authStore.returnUrl = '/dashboard'
  return authStore.login(username, password).catch( function (error) { console.log('failed' + error);  setErrors({ apiError: error })})
}

const showPassword = ref(false)

function togglePasswordVisibility() {
  const passwordField = document.querySelector('[name="password"]')
  showPassword.value = !showPassword.value
  passwordField.type = showPassword.value ? 'text' : 'password'
}

async function updateLanguage (language) {
	await loadLocaleMessages(i18n, language)
	locale.value = language
	localStorage.setItem('language', language)
	changeLocale(language)
	config.locale = language
  // probably safest to force this in the login form as the login form needs its config etc
  location.reload()
}

function getFlag (language) {
	// check for language to exist as a key in the flags dictionary, if it does, return the value
	if (flags[language]) {
		return flags[language].flag
	}
}
function languageDisplay (language) {
	// check for language to exist as a key in the flags dictionary, if it does, return the value
	if (flags[language]) {
		return flags[language].display
	}
}

onMounted(async () => {
  if (isApp) {
    const loader = $loading.show({
      fullPage: false,
      canCancel: false,
      container: loadingContainer.value,
      loader: "dots",
      opacity: "0.1"
    });
    setTimeout(function () {
      hidden.value = false
      loginDisplay.value = 'inline'
      loader.hide()
    }, 3000)
  } else {
      loginDisplay.value = 'block'
      hidden.value = false
  }
})

</script>

<template>
    <ion-page>
      <ion-content id="loginview">
        <div ref="loadingContainer" id="loadingContainer" />
        <site-header v-if="isApp === false" />
          <section v-if="hidden === false" class="loginForm my-5 py-4" :style="{display: loginDisplay}">
          <div v-if="hidden === false" class="container">
            <div class="row justify-content-center max-width-100 my-5">
              <div class="col-xl-4 col-sm-6">
                <h1 class="h1-title text-center">{{ t('login.login') }}</h1>
                <p class="fnt-18 mt-4">{{ t('login.continue-supercharging-your-language-learning-journey') }}</p>
              </div>
            </div>
            <div class="row justify-content-center max-width-100">
              <div class="col-xl-5 col-lg-6 col-sm-10 ">
                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
                  <div class="form-group form-group-inp">
                    <!-- <label>{{ t('login.username') }}</label> -->
                    <Field
                      name="username"
                      type="text"
                      :placeholder="t('login.username')"
                      class="form-control form-control-lg"
                      :class="{ 'is-invalid': errors.username }"
                      autocomplete="email"
                    />
                    <span class="icon-field"><i class="fa-solid fa-envelope"></i></span>
                    <div class="invalid-feedback">{{ errors.username }}</div>
                  </div>
                  <div class="form-group form-group-inp my-4">
                    <!-- <label>{{ t('login.password') }}</label> -->
                    <Field
                      name="password"
                      :placeholder="t('login.password')"
                      class="form-control form-control-lg"
                      :class="{ 'is-invalid': errors.password }"
                      :type="showPassword ? 'text' : 'password'"
                      autocomplete="current-password"
                    />
                    <span class="icon-field icon-password" @click="togglePasswordVisibility">
                      <i v-if="!showPassword" class="bi bi-eye-slash"></i>
                      <i v-else class="bi bi-eye"></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                  </div>
                  <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{ errors.apiError }}</div>
                  <div v-if="errors.non_field_errors" class="alert alert-danger mt-3 mb-0">{{ errors.non_field_errors }} NFE</div>
                  <div class="form-group form-action-btns">
                    <button class="btn btn-primary btn-primary-cs my-4" :disabled="isSubmitting">
                      <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1"></span>
                      {{ t('login.login') }}
                    </button>
                    <router-link to="/forgot-password/">{{ t('login.forgot-password') }}?</router-link>
                    <div class="loginform-signup-link mt-5 pt-5">
                      <p>{{ t('register.dont-have-an-account-yet') }}&nbsp;<router-link v-if="isApp" to="/">{{ t('register.sign-up-here') }}</router-link><router-link v-else to="/register/">{{ t('register.sign-up-here') }}</router-link></p>
                    </div>
                  </div>
                  <div v-if="isApp">
                    <CDropdown toggerText="Language" variant="nav-item" direction="dropup">
                      <CDropdownToggle togglerText="Dropdown">
                        <CButton>
                          <country-flag v-if="getFlag(locale)" :country="getFlag(locale)" size="normal" />
                          {{ languageDisplay(locale) }}
                        </CButton>
                      </CDropdownToggle>
                      <CDropdownMenu class="overflow-auto">
                        <CDropdownItem  v-for="language in languages" :key="language" @click="updateLanguage(language)">
                          <CDropdownDivider />
                          <country-flag v-if="getFlag(language)" :country="getFlag(language)" size="normal" />
                          {{ languageDisplay(language) }}
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                  <div>
                    <p><a target=_new href="https://www.lingo.ing/privacy-policy">{{ t('navbar.privacy-policy') }}</a> <a target=_new href="https://www.lingo.ing/terms-of-use">{{ t('navbar.terms-of-use') }}</a></p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
        <div class="cityBgBottom"></div>
        <site-footer v-if="isApp === false" />
      </ion-content>
    </ion-page>
  </template>
<style scoped>
  ion-content {
    --background: unset !important;
    background-image: url('@/assets/images/lingoing-bg-icons.png');
    background-size: 100%;
    background-repeat: repeat;
  }
  #loginview {
    font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  * >>> .vl-overlay {
    background-image: url('@/assets/images/appLoading.png') !important;
    background-size: 100%;
    repeat: no-repeat;
  }
   * >>> div.dropdown-menu {
     height: 45vh !important;
     min-width: 200px !important;
  }
</style>
