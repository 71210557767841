<script setup>
  import { ref, onMounted } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useI18n } from 'vue-i18n'
  import savedChat from '@/assets/images/savedchat.webp'
  import { useRouter } from 'vue-router'
  import ChatCategoryPanel from '@/components/ChatCategoryPanel.vue'
  const router = useRouter()
  const { t } = useI18n()
  const authStore = ref(false)
  const isLoading = ref(true)
  authStore.value = useAuthStore()
  const categories = ref([])
  onMounted(async () => {
    const response = await fetchWrapper.get(`/chat/category/list/`)
    console.log(response)
    categories.value = response
    isLoading.value = false
  })
  function return_href(category) {
    console.log('get return_href for ', category)
    return {
      name: 'ChatTemplates',
      params: { categoryID: category.id }
    }
  }
  function go_to_category(category) {
    console.log("go_to_category", category)
    router.push(return_href(category))
  }
  function to_to_history() {
    console.log("go_to_history")
    router.push("/chat/history/")
  }

</script>

<template>
  <nice-header />
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
  <section class="my-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="bg-white border-0 rounded border-light p-1 p-sm-2 p-md-5 w-100 fmxw-500">
            <div class="text-center text-md-center mb-4 mt-md-0 col-12">
              <h1 class="mb-0 h1-title">{{ t('categories.chat-topics') }}</h1>
              <hr />
              {{ t('categories.choose-from-one-of-these-fun-and-exciting') }}
            </div>
            <div class="d-flex justify-content-center gap-2"></div>
            <current-language />
            <div class="row">
              <div v-for="category in categories" :key="category.id" class="col-md-12 col-lg-6"> 
                <ChatCategoryPanel @go_to="go_to_category(category)">
                  <template #image>
                    <CCardImage width="125px" height="125px" :src="category.thumbnail_url" class="img-fluid thumbnail rounded-0" alt="image" />
                  </template>
                  <template #title>
                    <h2 class="mb-2 card-title">{{ t('categories.' + category.name) }}</h2>
                  </template>
                  <template #details>
                    <p v-html="t(`chat-category.description.${category.id}`)"/>
                  </template>
                </ChatCategoryPanel>
              </div>
              <div class="col-md-12 col-lg-6">
                <ChatCategoryPanel @go_to="to_to_history">
                  <template #image>
                    <CCardImage width="125px" height="125px" :src="savedChat" class="img-fluid thumbnail rounded-0" alt="image" />
                  </template>
                  <template #title>
                    <h2 class="mb-2 card-title">{{ t('navbar.active-chats') }}</h2>
                  </template>
                  <template #details>
                    <p v-html="t('content-block.text.1')"/>
                  </template>
                </ChatCategoryPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <nice-footer />
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
/* .thumbnail {
  max-width: 175px !important;
  max-height: 175px !important;
}*/
.link-like {
  cursor: pointer;
}
</style>

