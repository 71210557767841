<script setup>
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'

import { useAuthStore } from '@/stores/auth.store'
import { IonContent, IonPage } from '@ionic/vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { isApp } from '@/helpers/utils.js'

const schema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
})

function onSubmit(values, { setErrors }) {
  const authStore = useAuthStore()
  const { username } = values
  return authStore.forgotPassword(username).catch( function (error) { console.log('failed' + error);  setErrors({ apiError: error })})
}

</script>

<template>
  <ion-page>
    <ion-content id="forgotpasswordview">
      <site-header v-if="isApp === false"/>
        <section class="loginForm my-5 py-4">
          <div class="container">
            <div class="row justify-content-center max-width-100 my-5">
              <div class="col-xl-4 col-sm-6">
                <h1 class="h1-title text-center">{{ t('login.forgot-password') }}</h1>
                <p class="fnt-18 mt-4">{{ t('login.enter-your-email-to-reset-your-password') }}</p>
              </div>
            </div>
            <div class="row justify-content-center max-width-100">
              <div class="col-xl-4 col-sm-6">
                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
                  <div class="form-group">
                    <label>{{ t('login.email') }}</label>
                    <Field
                      name="username"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.username }"
                    />
                    <div class="invalid-feedback">{{ errors.username }}</div>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary btn-primary-cs my-4" :disabled="isSubmitting">
                      <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1"></span>
                      {{ t('login.reset-password') }}
                    </button>
                  </div>
                  <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{ errors.apiError }}</div>
                  <div v-if="errors.non_field_errors" class="alert alert-danger mt-3 mb-0">{{ errors.non_field_errors }} NFE</div>
                </Form>
              </div>
                <div class="loginForm-signup-link mt-5 pt-5">
                  <p>{{ t('register.already-a-member') }}&nbsp;
                     <router-link to="/login"> {{ t('register.log-in-here') }}</router-link>
                  </p>
                </div>
            </div>
          </div>
        </section>
      <div class="cityBgBottom"></div>
      <site-footer v-if="isApp === false"/>
    </ion-content>
  </ion-page>
</template>

<style scoped>
  ion-content {
    --background: unset !important;
    background-image: url('@/assets/images/lingoing-bg-icons.png');
    background-size: 100%;
    background-repeat: repeat;
  }
  #forgotpasswordview {
    font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
