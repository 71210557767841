// https://jasonwatmore.com/post/2022/05/26/vue-3-pinia-jwt-authentication-tutorial-example#auth-store-js

import { defineStore } from 'pinia'
import router from '@/router/index'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import supportedLanguages from "../locales/languages.json"
import supportedLanguagesByCode from "../locales/languagesByCode.json"
import flags from "../locales/flags.json"
import { Capacitor } from '@capacitor/core'
import { SavePassword } from 'capacitor-ios-autofill-save-password'
import { isApp, isIosApp, isAndroidApp } from '@/helpers/utils.js'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem('user')),
    details: JSON.parse(localStorage.getItem('details')),
    notificationIdentifiers: [],
    registeredNotificationIdentifiers: [],
    returnUrl: null,
    supportedLanguages: supportedLanguages,
    supportedLanguagesByCode: supportedLanguagesByCode,
    flags: flags,
    fontClass: 'font-regular',
    lastDetailsCheck: null,
  }),
  actions: {
    async login(username, password) {
      console.log("identifierDebug starting login", this.notificationIdentifiers, this.registeredNotificationIdentifiers)
      const user = await fetchWrapper.post(`/authenticate/`, { username, password }, {'credentials': 'omit'})

      if (user) {
        if (Capacitor.getPlatform() === 'ios') {
          await SavePassword.promptDialog({
            username: username,
            password: password
          });
        }
      }

      // update pinia state
      this.user = user

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user))

      await this.updateNotificationIdentifiers()
      await this.getUserDetails()

      // redirect to previous url or default to home page
      if (!user.registration_complete) {
        console.log('auth.store registration is incomplete, pushing')
        if (isApp) {
          router.push('/')
        } else {
          router.push('/register/')
        }
        return
      }
      console.log('redirect to returnUrl or / ', this.returnUrl || '/')
      router.push(this.returnUrl || '/')
    },
    async getUserDetails(lazy=false) {
      /* lazy is a flag that indicates we should only check every 1 minutes */
      if (lazy && this.lastDetailsCheck && (new Date() - this.lastDetailsCheck) < 60000) {
        console.log('getUserDetails lazy check, returning')
        return
      }
      const userDetails = await fetchWrapper.get(`/user-check/`)
      this.details = userDetails
      localStorage.setItem('details', JSON.stringify(userDetails))
      this.lastDetailsCheck = new Date()
    },
    async forgotPassword(email) {
      const response = await fetchWrapper.post(`/forgot-password/`, { email }, {'credentials': 'omit'})
      console.log('forgotPassword says', response)
    },
    async register(email, language, timezone) {
      const data = { email, language, timezone }
      if (isIosApp) {
        data['processor'] = 'Apple'
      } else if (isAndroidApp) {
        data['processor'] = 'Android'
      }
      // not setting anything would seem to default to stripe on the server side
      console.log('registerSending', data)
      const user = await fetchWrapper.post(`/register/`, data, {'credentials': 'omit'})
      console.log('registerResponse', user)
      console.log('auth login setting localStorage.user to', JSON.stringify(user))
      localStorage.setItem('user', JSON.stringify(user))
      this.user = user
      await this.updateNotificationIdentifiers()
      // we need to populate userDetails at this point, mainly to make the
      // menu disapear on following steps in registration process
      const userDetails = await fetchWrapper.get(`/user-check/`)
      this.details = userDetails
      localStorage.setItem('details', JSON.stringify(userDetails))
    },
    async updateRegistration(args, noreturn=false) {
      const response = await fetchWrapper.patch(`/update-registration/`, args)
      console.log('updateRegistration says', response, ' from ', JSON.stringify(args))
      await this.updateNotificationIdentifiers()
      if (noreturn === true) {
        console.log('updateRegistration completed, no redirect')
        return
      }
      router.push(this.returnUrl || '/')
    },
    async updateNotificationIdentifiers() {
      if (!isApp) {
        return
      }
      console.log("identifierDebug starting in updateNotification", this.notificationIdentifiers, this.registeredNotificationIdentifiers)
      // if we have an entry in self.notificationIdentifiers that does not exist in self.registeredNotificationIdentifiers, then register it
      console.log('identifierDebug checking notificationIdentifiers', this.notificationIdentifiers, this.registeredNotificationIdentifiers)
      for (let i = 0; i < this.notificationIdentifiers.length; i++) {
        if (!this.registeredNotificationIdentifiers.includes(this.notificationIdentifiers[i])) {
          console.log('identifierDebug registering notification identifier', this.notificationIdentifiers[i])
          // register the notification identifier
          await fetchWrapper.post(`/register-notification/`, { deviceIdentifier: this.notificationIdentifiers[i] })
          // add it to the list of registered notification identifiers
          this.registeredNotificationIdentifiers.push(this.notificationIdentifiers[i])
        }
      }
    },
    async validateEmail(validationUser, validationToken, password, password2) {
      const data = { validationUser, validationToken}
      if (password) {
        data['password'] = password
        data['password2'] = password2
      }
      const response = await fetchWrapper.post(`/validate-email/`, data, {'credentials': 'omit'})
      console.log('validateEmail says', response)
      return response
    },
    logout() {
      this.user = null
      localStorage.removeItem('user')
      // details holds important information, should also be cleared
      this.details = null
      localStorage.removeItem('details')
      // this forces the app to reload when doing this, in order to lose any state.
      // router.push here just results in orphaned pages with data in them
      window.location.assign('/login')
    }
  }
})
