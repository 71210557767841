<script setup lang="ts">
  import { useRoute } from 'vue-router'
  import { shallowRef } from 'vue'
  const props = defineProps({
    name: String
  })
  const $route = useRoute()
  const reactive_component = shallowRef(null)
  for (const matched of $route.matched) {
    if (matched.components[props.name]) {
      reactive_component.value = matched.components[props.name]
      break
    }
  }
</script>

<template>
  <component :is="reactive_component"/>
</template>

