<script setup>
  import { ref, onMounted } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { isIosApp, isAndroidApp } from '@/helpers/utils.js'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import ProcessorStripe from '@/components/ProcessorStripe.vue'
  import ProcessorApple from '@/components/ProcessorApple.vue'
  import ProcessorAndroid from '@/components/ProcessorAndroid.vue'
  const authStore = ref(false)
  authStore.value = useAuthStore()
  const billing = ref({})
  const isLoading = ref(true)
  const useStripe = ref(false)
  const useApple = ref(false)
  const useAndroid = ref(false)
  async function loadBillingComponent() {
    const response = await fetchWrapper.get(`/billing/`)
    console.log('billingResponse', response)
    billing.value = response
    isLoading.value = false
    if (isIosApp) {
      if (billing.value.current.processor === "Stripe" && billing.value.current.currently_active === true) {
        useStripe.value = true
      } else if (billing.value.current.processor === "Tester" && billing.value.current.currently_active === true) {
        useStripe.value = true
      } else {
        useApple.value = true
      }
    } else if (isAndroidApp) {
      if (billing.value.current.processor === "Stripe" && billing.value.current.currently_active === true) {
        useStripe.value = true
      } else if (billing.value.current.processor === "Tester" && billing.value.current.currently_active === true) {
        useStripe.value = true
      } else {
        useAndroid.value = true
      }
    } else {
      if (billing.value.current.processor === "Apple" && billing.value.current.currently_active === true) {
        useApple.value = true
      } else {
        useStripe.value = true
      }
    }

    console.log('variables are set from isIosApp', isIosApp, 'to ', 'useStripe', useStripe.value, 'useApple', useApple.value, 'useAndroid', useAndroid.value)

    if (billing.value.current.duration === 'trial') {
      // showPricingPlans.value = true
    } else if (!billing.value.current.will_renew) {
      // showPricingPlans.value = true
    }
    console.log('about to call component with billing', JSON.stringify(billing.value))
  }
  onMounted(async () => {
    await loadBillingComponent()
    isLoading.value = false
  })
</script>

<template>
  <nice-header />
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
  <processor-stripe v-if="useStripe" :billingInfo="billing" />
  <processor-apple v-if="useApple" :billingInfo="billing"/>
  <processor-android v-if="useAndroid" :billingInfo="billing"/>
  <nice-footer />
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .modal-backdrop {
     z-index: 20000;
   }
   .modal {
     z-index: 20001;
   }
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>


