<script setup>
  import { ref } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { IonContent, IonPage } from '@ionic/vue'
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const authStore = ref(false)
  authStore.value = useAuthStore()

</script>

<template>
  <ion-page>
    <ion-content>
      <site-header :needsReload="false" />
      <!-- Hero Section -->
      <section class="hero-section cityBgBottom mt-110 pb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 justify-content-left">
              <h1 class="h1-title mb-4">{{ t('help.help-area') }}</h1>
            </div>
            <div>
              <CAccordion>
                <CAccordionItem :item-key="1">
                  <CAccordionHeader>
                    {{ t('help.how-do-i-add-a-foreign-keyboard-to') }}
                  </CAccordionHeader>
                  <CAccordionBody>
                    <strong>
                      {{ t('help.please-follow-apples-instructions-on-how-to-add') }}
                    </strong>
                    <br />
                    <br />
                    <a href="https://support.apple.com/en-ca/guide/iphone/iph73b71eb/ios">https://support.apple.com/en-ca/guide/iphone/iph73b71eb/ios</a>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem :item-key="2">
                  <CAccordionHeader>
                    {{ t('help.how--do-i-add-a-foreign-keyboard') }}
                  </CAccordionHeader>
                  <CAccordionBody>
                    <strong>
                      {{ t('help.please-follow-googles-instructions-on-how-to-add') }}
                    </strong>
                    <br />
                    <br />
                    <a href="https://support.google.com/accounts/answer/32047?hl=en&co=GENIE.Platform%3DAndroid">https://support.google.com/accounts/answer/32047?hl=en&co=GENIE.Platform%3DAndroid</a>
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
            </div>
          </div>
        </div>
      </section>
      <!-- End -->
      <site-footer />
    </ion-content>
  </ion-page>
</template>

<style scoped>
  ion-content {
		--background: unset !important;
    background-image: url('@/assets/images/lingoing-bg-icons.png');
    background-size: 100%;
    background-repeat: repeat;
  }
	h3 > button {
		color: var(--bs-heading-color) !important;
	}
  #homeview {
    font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
