import { useDateFormat } from '@vueuse/core'
import { getPlatforms } from '@ionic/vue'
import allLanguages from '../locales/languageIds.json'
import { fetchWrapper } from '@/helpers/fetch-wrapper.js'
import { LingoCapacitor } from 'lingo-capacitor'
import { useAuthStore} from '@/stores/auth.store'

const timeDisplay = 'YYYY-MM-DD HH:mma'
const dateDisplay = 'YYYY-MM-DD'

function standardizeDate(time) {
  if (typeof time === 'string') {
    time = new Date(time)
  }
  return useDateFormat(time, dateDisplay).value
}

function standardizeTime(time) {
  if (typeof time === 'string') {
    time = new Date(time)
  }
  return useDateFormat(time, timeDisplay).value
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function checkIsApp() {
  if (getPlatforms().includes('capacitor')) {
    return true
  }
  return false
}

function checkIsDesktop() {
  if (getPlatforms().includes('desktop')) {
    return true
  }
  return false
}

function checkIsIosApp() {
  if (getPlatforms().includes('capacitor')) {
    if (getPlatforms().includes('ios')) {
      return true
    }
  }
  return false
}

function checkIsAndroidApp() {
  if (getPlatforms().includes('capacitor')) {
    if (getPlatforms().includes('android')) {
      return true
    }
  }
  return false
}

function getLanguageIdByName(language) {
  return allLanguages.find(lang => lang.language === language).id
}

async function markTourViewed(tour) {
  var field_name = 'shown_' + tour + '_tour'
  await fetchWrapper.patch('/profile/', {
    [field_name]: true
  })
}

async function getSupportedKeyboards() {
  const { supportedLanguagesByCode } = useAuthStore()
  const input_modes = await LingoCapacitor.getAllInputModes()
  if (input_modes.inputModes.includes('web')) {
    return []
    // return ['en', 'es']
  } else {
    var keyboards = input_modes.inputModes.filter((item) => item !== 'emoji')
    var languageKeyboards = []
    for (var i = 0; i < keyboards.length; i++) {
      var supportedKeyboard = keyboards[i]
      // discard anything after the first hyphen if it exists
      if (supportedKeyboard.includes('-')) {
        supportedKeyboard = supportedKeyboard.split('-')[0]
      }
      for (const key in supportedLanguagesByCode) {
        console.log('compare', supportedKeyboard, key, supportedLanguagesByCode[key])
        if (supportedKeyboard === key) {
          languageKeyboards.push(supportedLanguagesByCode[key])
          break
        }
      }
    }
  }
  return languageKeyboards
}

const isApp = checkIsApp()
const isDesktop = checkIsDesktop()
const isIosApp = checkIsIosApp()
const isAndroidApp = checkIsAndroidApp()

export {
  standardizeTime,
  standardizeDate,
  capitalizeFirstLetter,
  isApp,
  isDesktop,
  isIosApp,
  isAndroidApp,
  getLanguageIdByName,
  markTourViewed,
  getSupportedKeyboards
}
