<script setup>
  import { ref, onMounted, inject } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useI18n } from 'vue-i18n'
  import chardCardPic from '@/assets/images/dashboard-chat.webp'
  import vocabularyCardPic from '@/assets/images/vocabulary-card.webp'
  import flashCardsPic from '@/assets/images/flashcards.webp'
  import studyPicCard from '@/assets/images/study-pic.webp'
  import readingTogetherPic from '@/assets/images/dashboard-reading-together.webp'
  import { standardizeTime, capitalizeFirstLetter, markTourViewed, isIosApp, isAndroidApp } from '@/helpers/utils'
  import { useRouter } from 'vue-router'
  import { env } from '@/helpers/env.js'
  import DashboardPanel from '@/components/DashboardPanel.vue'
  const router = useRouter()
  const { t } = useI18n()
  const authStore = ref(false)
  const isLoading = ref(true)
  authStore.value = useAuthStore()
  const profile = ref({})
  const topContent = ref(false)
  const showBillingWarning = ref(false)
  const showBillingLock = ref(false)

  onMounted(async () => {
    await loadData()
  })
  const tour = inject('initialTour')
  tour.on("complete", () => {
    setTimeout(() => {
      topContent.value.scrollIntoView({ behavior: "smooth" })
    }, 500)
  })
  tour.on("cancel", () => {
    setTimeout(() => {
      topContent.value.scrollIntoView({ behavior: "smooth" })
    }, 500)
  })

  const tourStep1Text = t('tour.congratulations-on-your-language-journeyif-youd-like-to')
  const tourStep2Text = t('tour.for-something-fun-and-to-work-on-words')

  async function loadData() {
    isLoading.value = true
    var platform = ''
    if (isIosApp) {
      platform = 'ios'
    } else if (isAndroidApp) {
      platform = 'android'
    } else {
      platform = 'web'
    }
    const response = await fetchWrapper.get(`/profile/?release_version=${env.VUE_APP_RELEASE_VERSION}&platform=${platform}`)
    console.log(response)
    profile.value = response
    showBillingWarning.value = profile.value.billing_warning
    showBillingLock.value = profile.value.billing_locked
    console.log('dashboard check', authStore.value)
    if (!authStore.value.details.registration_complete) {
      router.push('/register')
      return
    }
    isLoading.value = false
    if (!response.shown_dashboard_tour) {
      tour.start()
      markTourViewed('dashboard')
    }
  }
</script>

<template>
  <nice-header />
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
    <CModal :visible="showBillingLock" @close="() => { router.push('/billing')}"> 
		<CModalHeader>
			<CModalTitle>{{ t('billing.account-unavailable---billing-issue') }}</CModalTitle>
		</CModalHeader>
		<CModalBody>
      <p>{{ t('billing.we-are-sorry-but-your-account-is-currently') }}</p>
		</CModalBody>
	</CModal>
  <section class="my-5" ref="topContent">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="bg-white border-0 rounded border-light p-1 p-sm-2 p-md-5 w-100 fmxw-500">
            <div class="text-center text-md-center mb-1 mt-md-0 col-12">
              <h5 v-if="profile.first_name">{{ t("dashboard.welcome-back-firstname", { first_name: capitalizeFirstLetter(profile.first_name) }) }}</h5>
            </div>
            <CAlert color="primary" v-if="isIosApp && profile.retired_ios">
              {{ t('profile.there-is-a-newer-version-of-lingo-ing-available') }}
              <br><br>
              <b><a href="https://apps.apple.com/ca/app/lingo-ing-ai-language-tutor/id6581480179">{{ t('profile.please-upgrade-here') }}</a></b>
            </CAlert>
            <CAlert color="warning" v-if="profile.pending_validation">{{ t("dashboard.we-have-sent-a-validation-email-to-you", {email: profile.email}) }}</CAlert>
            <CAlert color="warning" v-if="showBillingWarning && !showBillingLock">
            <b>{{ t("billing.billing-issue") }}</b><br />
              {{ t("billing.we-seem-to-be-having-an-issue-with") }}<br /><router-link to="/billing">{{ t('billing.billing-area') }}</router-link><br>
            </CAlert>
            <div class="d-flex gap-2"></div>
            <div class="row">
              <div class="col-md-12 mt-1">
                <DashboardPanel to="/chat" :image="chardCardPic"
									v-tour-step:1="{
									tour: initialTour,
									options: {
										attachTo: { on: 'top' },
                    text: tourStep1Text,
										buttons: [
										{
                      classes: 'btn btn-primary btn-primary-cs btn-primary-sm',
											text: 'Next',
											action: initialTour.next,
										},
										],
									}
									}"
                >
                  <template v-slot:title>{{ t('dashboard.chat-mode') }}</template>
                  <template v-slot:description>{{ t('dashboard.boost-your-language-skills-with-our-exciting-chat') }}</template>
                  <template v-slot:details>
                    <small v-if="profile.last_chat_time" class="text-body-secondary">{{ t('dashboard.last-message-lastchattime', { last_chat_time: standardizeTime(profile.last_chat_time)}) }}</small>
                    <small v-else class="text-body-secondary">{{ t('dashboard.over-50-ai-language-tutors-waiting') }}</small>
                  </template>
                  <template #call-to-action>
                    {{ t('dashboard.chat-now') }}
                  </template>

                </DashboardPanel>
                <DashboardPanel to="/cards" :image="flashCardsPic"
                  v-tour-step:2="{
									tour: initialTour,
									options: {
										attachTo: { on: 'top' },
                    text: tourStep2Text,
										buttons: [
										{
                      classes: 'btn btn-primary btn-primary-cs btn-primary-sm',
											text: 'Next',
											action: initialTour.next,
										},
										],
									}
									}"
                >
                  <template v-slot:title>{{ t('dashboard.flashcards') }}</template>
                  <template v-slot:description>{{ t('dashboard.try-out-our-flashcards-area-here-you-can') }}</template>
                  <template v-slot:details>
                    <small v-if="profile.num_groups" class="text-body-secondary">{{ t('dashboard.you-are-subscribed-to-numgroups-flashcard-decks', { num_groups: profile.num_groups}) }}</small>
                    <small v-else class="text-body-secondary">{{ t('dashboard.you-are-not-subscribed-to-any-flashcard-decks') }}</small>
                  </template>
                  <template #call-to-action>
                    {{ t('dashboard.review-flashcards') }}
                  </template>
                </DashboardPanel>
                <DashboardPanel to="/course" :image="studyPicCard">
                  <template v-slot:title>{{ t('dashboard.study') }}</template>
                  <template v-slot:description>{{ t('dashboard.welcome-to-the-study-section-discover-well-organized-chapters') }}</template>
                  <template v-slot:details>
                    <small class="text-body-secondary">{{ t('dashboard.not-sure-where-to-start---use') }}</small>
                  </template>
                  <template #call-to-action>
                    {{ t('dashboard.study-textbook') }}
                  </template>
                </DashboardPanel>
                <DashboardPanel to="/vocab" :image="vocabularyCardPic">
                  <template v-slot:title>{{ t('dashboard.saved-vocabulary-list') }}</template>
                  <template v-slot:description>{{ t('dashboard.welcome-to-your-personal-vocabulary-vault-save-and') }}</template>
                  <template v-slot:details>
                    <CCardText v-if="profile.vocabulary_size > 0"><small class="text-body-secondary">{{ t('dashboard.you-have-count-words-saved-', { count: profile.vocabulary_size}) }}</small></CCardText>
                    <CCardText v-else><small class="text-body-secondary">{{ t('dashboard.you-have-no-words-saved-to-your-vocabulary') }}</small></CCardText>
                  </template>
                  <template #call-to-action>
                    {{ t('dashboard.view-vocab') }}
                  </template>
                </DashboardPanel>
                <DashboardPanel to="/article/list/" :image="readingTogetherPic">
                  <template v-slot:title>{{ t('dashboard.reading-together') }}</template>
                  <template v-slot:description>{{ t('dashboard.reading-together-description') }}</template>
                  <template v-slot:details>
                  </template>
                  <template #call-to-action>
                    {{ t('dashboard.read-articles') }}
                  </template>
                </DashboardPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <nice-footer />
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>


