<script setup>
  import logoWhite from '@/assets/images/lingo-white-logo.png'
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()

  function scrollFix(e) {
    console.log(scrollFix)
    const targetElement = document.getElementById(e)
    setTimeout(function () {
      if (e === 'homeview') {
        window.scrollTo(0, 0)
      } else  {
        console.log('scrollFix from footer to', e)
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }, 500)
  }
</script>


<template>
  <footer class="py-5">
    <div class="container">
      <div class="footer-logo my-5">
        <img :src="logoWhite" alt="Logo" />
      </div>
      <nav class="footer-navigation my-5">
        <ul class="footer-nav">
          <li>
            <router-link to="/" @click="scrollFix('headerLogo')">{{ t('navbar.home') }}</router-link>
          </li>
          <li>
            <router-link to="/#features" @click="scrollFix('features')">{{ t('navbar.features') }}</router-link>
          </li>
          <li>
            <router-link to="/#pricing" @click="scrollFix('pricing')">{{ t('navbar.pricing') }}</router-link>
          </li>
          <li>
            <router-link to="/#faq" @click="scrollFix('faq')">{{ t('navbar.faq') }}</router-link>
          </li>
          <li>
            <router-link to="/terms-of-use">{{ t('navbar.terms-of-use') }}</router-link>
          </li>
          <li>
            <router-link to="/privacy-policy">{{ t('navbar.privacy-policy') }}</router-link>
          </li>
          <li>
            <router-link to="/login">{{ t('navbar.login') }}</router-link>
          </li>
        </ul>
        <!-- <ul class="footer-social-links mt-5">
          <li>
            <a href="#" target="_blank"><i class="bi bi-facebook"></i></a>
          </li>
          <li>
            <a href="#" target="_blank"><i class="bi bi-twitter-x"></i></a>
          </li>
          <li>
            <a href="#" target="_blank"><i class="bi bi-youtube"></i></a>
          </li>
        </ul>
        -->
      </nav>
      <p class="footer-copyright pt-5 pb-3"> {{ t('footer.copyright') }} &#169; Lingo.ing 2024. All rights reserved</p>
    </div>
  </footer>
</template>
