import { defineStore } from 'pinia'

export const useCookieStore = defineStore({
  id: 'cookie-store',
  state: () => ({
    trackingAllowed: localStorage.getItem('cookie.trackingAllowed') || false,
    shownDisclaimer: localStorage.getItem('cookie.shownDisclaimer') || false,
  }),
  actions: {
    async setTracking(allowed) {
      console.log('set tracking to' + allowed)
      this.trackingAllowed = allowed
      localStorage.setItem('cookie.trackingAllowed', allowed)
    },
    async acceptAllCookies() {
      this.setTracking(true)
    },
    async markDisclaimerAsShown() {
      this.shownDisclaimer = true
      localStorage.setItem('cookie.shownDisclaimer', true)
    }
  }
})

