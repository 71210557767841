<script setup>
  import { useRoute } from 'vue-router'
  import { ref, onMounted } from 'vue'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useI18n } from 'vue-i18n'
  const $route = useRoute()
  const { t } = useI18n()
  const languageID = parseInt($route.params.languageID)
  const profile = ref({})
  const isLoading = ref(true)
  const learning_language = ref(undefined)
  const speech_language = ref([])
  const ruby_annotation_options = ref([])
  async function load_profile() {
    const response = await fetchWrapper.get(`/profile/`)
    console.log(response)
    profile.value = response
  }
  onMounted(async () => {
    await load_profile()
    profile.value.languages.forEach((language) => {
      if (language.language_id === languageID) {
        learning_language.value = language
      }
    })
    if (learning_language.value.language == 'Japanese') {
      ruby_annotation_options.value = [
        {value: 'furigana', text: 'Furigana'},
        {value: 'romaji', text: 'Romaji'},
      ]
    }
    else if (learning_language.value.language == 'Chinese') {
      ruby_annotation_options.value = [
        {value: 'pinyin', text: 'Pinyin'},
      ]
    }
    else if (learning_language.value.language == 'Korean') {
      ruby_annotation_options.value = [
        {value: 'romanization', text: 'romanization'},
      ]
    }
    else {
      ruby_annotation_options.value = [
      ]
    }
    const speech_language_response = await fetchWrapper.post(`/chat/voice/list/`, {language: learning_language.value.language})
    speech_language.value = speech_language_response
    isLoading.value = false
  })

  async function set_voice(voice) {
    learning_language.value.default_voice = voice
    await fetchWrapper.patch('/profile/language/', {
      language: learning_language.value.language,
      default_voiceID: voice.id
    })
  }
  async function set_male_voice(voice) {
    learning_language.value.default_male_voice = voice
    await fetchWrapper.patch('/profile/language/', {
      language: learning_language.value.language,
      default_male_voiceID: voice.id
    })
  }
  async function set_female_voice(voice) {
    learning_language.value.default_female_voice = voice
    await fetchWrapper.patch('/profile/language/', {
      language: learning_language.value.language,
      default_female_voiceID: voice.id
    })
  }
  async function set_ruby_annotation(annotation) {
    learning_language.value.ruby_annotation = annotation
    await fetchWrapper.patch('/profile/language/', {
      language: learning_language.value.language,
      ruby_annotation: annotation
    })
  }
  async function set_automatically_annotate(automatically_annotate) {
    learning_language.value.automatically_annotate = automatically_annotate
    await fetchWrapper.patch('/profile/language/', {
      language: learning_language.value.language,
      automatically_annotate: automatically_annotate
    })
  }
</script>

<template>
  <nice-header />
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
  <section class="my-5" style="height: 90vh">
    <div v-if="!isLoading" class="col-lg-10 offset-lg-1 col-xs-12 col-sm-12">
      <div id="findMe" class="bg-white shadow border-0 rounded border-light p-1 p-sm-2 p-md-5 w-100 fmxw-500" style="height: 90vh">
        <div class="text-center text-md-center mb-4 mt-md-0 col-12">
          <h1 class="mb-0 h1-title">{{ t('languageconfig.language-configuration') }}</h1>
          <p class="mb-0">{{ t('languageconfig.configure-your-language-settings-for--learninglanguage-', {learning_language: learning_language.language}) }}</p>
          <hr />
        </div>
        <div class="d-flex justify-content-center gap-2"></div>
        <div class="row">
          <div class="col-3 offset-md-3 ">
            <div class="mb-3">
              <label for="name">{{ t('languageconfig.preferred-voice') }}</label>
            </div>
          </div>
          <div class="col-3">
            <div class="mb-3">
              <CDropdown style="min-width: 200px">
                <CDropdownToggle togglerText="Dropdown">
                <CButton><img :src="`/voice_profile/${learning_language.default_voice.id}.jpg`"/>{{ learning_language.default_voice.name }}</CButton>
                </CDropdownToggle>
                <CDropdownMenu class="overflow-auto">
                <CDropdownItem v-for="voice in speech_language" v-bind:key="voice.id" @click="set_voice(voice)"><img :src="`/voice_profile/${voice.id}.jpg`"/>{{ voice.name }}</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 offset-md-3 ">
            <div class="mb-3">
              <label for="name">{{ t('languageconfig.preferred-male-voice') }}</label>
            </div>
          </div>
          <div class="col-3">
            <div class="mb-3">
              <CDropdown>
                <CDropdownToggle togglerText="Dropdown">
                <CButton><img :src="`/voice_profile/${learning_language.default_male_voice.id}.jpg`"/>{{ learning_language.default_male_voice.name }}</CButton>
                </CDropdownToggle>
                <CDropdownMenu class="overflow-auto">
                <CDropdownItem v-for="voice in speech_language" v-bind:key="voice.id" @click="set_male_voice(voice)"><img :src="`/voice_profile/${voice.id}.jpg`"/>{{ voice.name }}</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 offset-md-3 ">
            <div class="mb-3">
              <label for="name">{{ t('languageconfig.preferred-female-voice') }}</label>
            </div>
          </div>
          <div class="col-3">
            <div class="mb-3">
              <CDropdown>
                <CDropdownToggle togglerText="Dropdown">
                <CButton><img :src="`/voice_profile/${learning_language.default_female_voice.id}.jpg`"/>{{ learning_language.default_female_voice.name }}</CButton>
                </CDropdownToggle>
                <CDropdownMenu class="overflow-auto">
                <CDropdownItem v-for="voice in speech_language" v-bind:key="voice.id" @click="set_female_voice(voice)"><img :src="`/voice_profile/${voice.id}.jpg`"/>{{ voice.name }}</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 offset-md-3 ">
            <div class="mb-3" v-if="ruby_annotation_options.length">
              <label for="name">{{ t('languageconfig.preferred-annotation') }}</label>
            </div>
          </div>
          <div class="col-3">
            <div class="mb-3" v-if="ruby_annotation_options.length">
              <CDropdown>
                <CDropdownToggle togglerText="Dropdown">
                <CButton v-if="learning_language.ruby_annotation">{{ learning_language.ruby_annotation }}</CButton>
                <CButton v-else>{{ t('languageconfig.none') }}</CButton>
                </CDropdownToggle>
                <CDropdownMenu>
                <CDropdownItem v-for="option in ruby_annotation_options" v-bind:key="option.value" @click="set_ruby_annotation(option.value)">{{ option.text }}</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 offset-md-3 ">
            <div class="mb-3" v-if="ruby_annotation_options.length">
              <label for="name">{{ t('languageconfig.automatically-annotate') }}</label>
            </div>
          </div>
          <div class="col-3">
            <div class="mb-3" v-if="ruby_annotation_options.length">
              <CFormCheck
                v-model:checked="learning_language.automatically_annotate"
                @change="set_automatically_annotate(!learning_language.automatically_annotate)"
                id="automatically_annotate"
                name="automatically_annotate"
              />
            </div>
          </div>
        </div>
				<router-link to="/profile" class="btn btn-primary-cs btn-primary-lg">{{ t('profile.back-to-profile') }}</router-link>
        <nice-footer />
      </div>
    </div>
  </section>
</template>

<style scoped>
  div.btn-group {
    min-width: 200px;
  }
  * >>> div.dropdown-menu {
    height: 45vh !important;
    min-width: 200px !important;
  }
</style>



