<template>
  <div style="display: none">
    <b>matched:</b> <span v-if="$route.matched">{{ $route.matched[1] }}</span><span style="background-color: red" v-else>NO MATCH</span><br>
    <div id="json" width="100%" height="500px" style="overflow:scroll; height: 400px">
      <strong><pre>{{ routerJson }}</pre></strong>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useRouter} from 'vue-router'
  import { ref } from 'vue'
  const router = useRouter()
  const routerJson = ref("")
  routerJson.value = JSON.stringify(router, undefined, 2)

</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
