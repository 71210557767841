const env = {}
try {
  if (process.env) {
    // copy keys from process.env to env
    Object.keys(process.env).forEach(key => {
      env[key] = process.env[key]
    })
  }
} catch (e) {
  // totally normal in vite
}
var mode = 'development'
if (import.meta.env) {
  // copy keys from import.meta.env to env but rewrite the string VITE to VUE
  Object.keys(import.meta.env).forEach(key => {
    env[key.replace(/^VITE_/, 'VUE_APP_')] = import.meta.env[key]
  })
}
if (env['VUE_APP_API_BASE_URL'].includes('www')) {
  mode = 'production'
}

export { env, mode }

