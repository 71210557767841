import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { AVPlugin } from "vue-audio-visual";
import Vue3DirectiveShepherd from 'vue3-directive-shepherd';
import 'shepherd.js/dist/css/shepherd.css';


/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import '@ionic/vue/css/palettes/dark.always.css'; */
/* @import '@ionic/vue/css/palettes/dark.class.css'; */
/* import '@ionic/vue/css/palettes/dark.system.css' */

/* Theme variables */
import './theme/variables.css'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import { plugin, defaultConfig } from "@formkit/vue";
import { createMultiStepPlugin } from '@formkit/addons'
import "@formkit/themes/genesis";
import '@formkit/addons/css/multistep'

import CountryFlag from 'vue-country-flag-next'

import DashboardView from '@/views/DashboardView.vue'
import CardCategories from '@/views/CardCategories.vue'
import ChatCategories from '@/views/ChatCategories.vue'
import NiceHeader from '@/components/NiceHeader.vue'
import NiceFooter from '@/components/NiceFooter.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import CardPreview from '@/components/CardPreview.vue'
import VocabEntry from '@/components/VocabEntry.vue'
import RouteDebug from '@/components/RouteDebug.vue'
import CurrentLanguage from '@/components/CurrentLanguage.vue'
import { AudioManager } from '@/helpers/audiomanager'
import { PopoverManager } from '@/helpers/popovermanager'
import { isIosApp, isAndroidApp } from '@/helpers/utils.js'

import { createI18n } from 'vue-i18n'

import l_en from "./locales/en.json"

var languages = {
  en: l_en,
}

const lang = localStorage.getItem('language') || 'en'

const i18n = createI18n({
  locale: lang,
  fallbackLocale: "en",
  messages: languages,
  legacy: false
})

import { ar, az, bg, bs, ca, cs, da, de, el, en, es, fa, fi, fr, fy, he, hr, hu, id, it, ja, kk, ko, lt, lv, nb, nl, pl, pt, ro, ru, sk, sl, sr, sv, tet, tg, th, tr, uk, uz, vi, zh, is, mn } from '@formkit/i18n'

const i18nLocales = { ar, az, bg, bs, ca, cs, da, de, el, en, es, fa, fi, fr, fy, he, hr, hu, id, it, ja, kk, ko, lt, lv, nb, nl, pl, pt, ro, ru, sk, sl, sr, sv, tet, tg, th, tr, uk, uz, vi, zh, is, mn }

import { cilArrowCircleRight, cilKeyboard, cilLightbulb, cilReload } from '@coreui/icons-pro';
var icons = { cilArrowCircleRight, cilKeyboard, cilLightbulb, cilReload }

const t = i18n.global.t

const tourOptions = {
	router,
	tourMap: {
		initialTour: {
			useModalOverlay: true,
			defaultStepOptions: {
				title: t('tour.welcome-to-lingo-ing'),
				classes: 'shepherd-custom',
				cancelIcon: {
					enabled: true
				},
				scrollTo: {
					behaviour: 'smooth',
					block: 'center'
				},
				scrollToHandler: function (element) {
					setTimeout(() => {
						console.log('scroll to ', element)
						element.scrollIntoView({ behavior: 'smooth', block: 'center' })
					}, 500) 
				}
			},
		},
		flashcardTour: {
			useModalOverlay: true,
			defaultStepOptions: {
				title: t('tour.flashcards-introduction'),
				classes: 'shepherd-custom',
				cancelIcon: {
					enabled: true
				},
				scrollTo: {
					behaviour: 'smooth',
					block: 'center'
				},
				scrollToHandler: function (element) {
					setTimeout(() => {
						console.log('scroll to ', element)
						element.scrollIntoView({ behavior: 'smooth', block: 'center' })
					}, 500) 
				}
			},
		},
		chatTour: {
			useModalOverlay: true,
			defaultStepOptions: {
				title: t('tour.introduction-to-chat'),
				classes: 'shepherd-custom',
				cancelIcon: {
					enabled: true
				},
				scrollTo: {
					behaviour: 'smooth',
					block: 'center'
				},
				scrollToHandler: function (element) {
					setTimeout(() => {
						console.log('scroll to ', element)
						element.scrollIntoView({ behavior: 'smooth', block: 'center' })
					}, 500) 
				}
			},
		},
	},
}

const getConfig = () => {
	return {
		hardwareBackButton: false,
		swipeBackEnabled: false,
		animated: false
	}
}

import { LOG_LEVEL, Purchases } from "@revenuecat/purchases-capacitor"

const app = createApp(App)
Sentry.init({
  app,
  dsn: "https://0728acb22a6794603f93ca10bfeff5c4@o4507658790371328.ingest.us.sentry.io/4507658793189376",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
app.use(createPinia())
app.use(IonicVue, getConfig())
app.use(router)
app.use(CoreuiVue)
app.use(plugin, defaultConfig({ locales: i18nLocales, locale: lang, plugins: [createMultiStepPlugin()] }))
app.use(AVPlugin)
app.use(Vue3DirectiveShepherd, tourOptions)
app.provide('audiomanager', new AudioManager())
app.provide('popovermanager', new PopoverManager())
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('dashboard-view', DashboardView)
app.component('card-categories', CardCategories)
app.component('card-preview', CardPreview)
app.component('chat-categories', ChatCategories)
app.component('country-flag', CountryFlag)
app.component('nice-header', NiceHeader)
app.component('nice-footer', NiceFooter)
app.component('vocab-entry', VocabEntry)
app.component('site-header', SiteHeader)
app.component('site-footer', SiteFooter)
app.component('route-debug', RouteDebug)
app.component('loading-overlay', Loading)
app.component('current-language', CurrentLanguage)
app.use(i18n)

app.provide('i18n', i18n)

router.isReady().then(() => {
  app.mount('#app')
	// configure variables for RevenueCat if running in iOS app
	if (isIosApp) {
		var revenuecatKey = "appl_gLbaKzDWzugwemFzYbEmMkdbyYg"
		const configure = async () => {
			await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
			await Purchases.configure({
				apiKey: revenuecatKey
			})
			Purchases.enableAdServicesAttributionTokenCollection();
			await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
				console.log('customerInfo listener updated', customerInfo)
			});
		}
		configure().then( () => { "RevenueCat configured" } )
	} else if (isAndroidApp) {
		var revenuecatKey = "goog_GCMyGbSKNCjsOdArhpsnTCWAUPR"
		console.log('setting up revenuecat with ', revenuecatKey)
		const configure = async () => {
			await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
			await Purchases.configure({
				apiKey: revenuecatKey
			})
			await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
				console.log('customerInfo listener updated', customerInfo)
			});
		}
		configure().then( () => { "revenuecat configured" } )
	}
})

