export class PopoverManager {
  constructor() {
    this.popovers = [];
  }

  addPopover(popover) {
    this.popovers.push(popover);
  }

  removePopover(popover) {
    this.popovers = this.popovers.filter(p => p !== popover);
  }

  closeAll() {
    this.popovers.forEach(p => {
      const msgElement = document.querySelector(`.msg[messageID="${p.messageID}"]`)
      if (msgElement && p.popoverVisible) {
        msgElement.click()
      }
      p.popoverVisible = false
    });
  }
}
