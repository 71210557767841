<script setup>
  import { ref, defineProps } from 'vue'
  const props = defineProps({
    to: String,
    image: Object,
  })
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const show_details = ref(false)
</script>
<template
  >
  <CCard class="mb-1 shadow dashboard-card my-3">
    <CRow class="g-0">
      <CCol :xs="4" :sm="4" :md="3" class="image-div d-flex align-items-center">
        <router-link :to="props.to">
          <CCardImage class="rounded-0" :src="image" />
        </router-link>
      </CCol>
      <CCol :xs="8" :sm="8" :md="9">
        <CCardBody>
          <CCardTitle class="mb-2">
            <slot name="title" />
           <i v-if="!show_details" class="bi bi-chevron-down show-details d-block d-sm-none" @click="show_details = !show_details"></i>
           <i v-if="show_details" class="bi bi-chevron-up show-details d-block d-sm-none" @click="show_details = !show_details"></i>
          </CCardTitle>
          <div class="d-block d-sm-none">
            <CCardText v-if="show_details"><slot name="description" /></CCardText>
            <CCardText v-else class="clamped"><slot name="description" /></CCardText>
          </div>
          <div class="d-none d-sm-block">
            <CCardText><slot name="description" /></CCardText>
          </div>
          <CCardText><slot name="details" /></CCardText>
          <router-link :to="props.to" class="text-decoration-none">
            <CButton color="primary" class="btn-primary-cs btn-primary-sm">
              <slot name="call-to-action">
                {{ t('dashboard.start-now') }}
              </slot>
            </CButton>
          </router-link>
        </CCardBody>
      </CCol>
    </CRow>
  </CCard>
</template>
<style scoped lang="scss">
  .show-details {
    cursor: pointer;
    float: right;
  }
  .dashboard-card {
    padding: 5px 10px;
    .image-div {
      display: inline-block;
    }
    .image-div img {
    }
    .card-body{
        text-align: left;
        padding: 5px 10px;
    }
    .card-title{
        font-size: 1.2rem;
        color: var(--text-color);
        font-weight: 600;
    }
    .card-text{
        color: var(--secondary-text-color);
    }
  }
  .clamped {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
