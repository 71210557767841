<script setup>
import { ref, onMounted } from 'vue'
const password = ref('')
const password2 = ref('')
const passwordErrors = ref([])
const password2Errors = ref([])
const registrationComplete = ref(false)
const emailValidation = ref(false)
const emailValidationErrors = ref(false)
const emailValidationSuccess = ref(false)
const passwordValidationErrors = ref(false)
const passwordValidationSuccess = ref(false)

import { useAuthStore } from '@/stores/auth.store'
import { useI18n } from 'vue-i18n'
const isLoading = ref(true)
const { t } = useI18n()
import { useRoute } from 'vue-router'
import { IonContent, IonPage } from '@ionic/vue'
import { isApp } from '@/helpers/utils.js'

const $route = useRoute()
const authStore = useAuthStore()

const handleIconClick = (node) => {
  node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
  node.props.type = node.props.type === 'password' ? 'text' : 'password'
}

onMounted(async () => {
  if ($route.params.validation_token) {
    const validationUser = $route.params.validation_user
    const validationToken = $route.params.validation_token
    emailValidation.value = true
    console.log('attempt to validate', validationToken)
    authStore.returnUrl = '/dashboard'
    const response = await authStore.validateEmail(validationUser, validationToken).catch(
      function (error) { 
        console.log('registerError');
        console.log(error);
        emailValidationErrors.value = error
      }
    ).then((response) => {
      if (emailValidationErrors.value === false) {
        emailValidationSuccess.value = t('register.your-email-has-been-validated---please-set')
        console.log('now we have a response', response)
      }
    })
    console.log('validationToken says', response)
    isLoading.value = false
  }
})

async function checkPasswordStrength(password) {
  // check if password is a good strength password, if not, set a message in passwordValidationErrors
  if (password.length < 8) {
    passwordValidationErrors.value = "Password must be at least 8 characters long."
  }
  // check if password contains multiple character types
  let characterTypes = 0
  if (password.match(/[a-z]/)) {
    characterTypes++
  }
  if (password.match(/[A-Z]/)) {
    characterTypes++
  }
  if (password.match(/[0-9]/)) {
    characterTypes++
  }
  if (password.match(/[^a-zA-Z0-9]/)) {
    characterTypes++
  }
  if (characterTypes < 3) {
    passwordValidationErrors.value = "Password must contain at least 3 of the following: lowercase letter, uppercase letter, number, special character."
    return false
  }
  return true
}

async function submitPasswordHandler() {
  // check if password.value and password2.value are good strength passwords, if not, set a message in passwordValidationErrors
  const strength = await checkPasswordStrength(password.value)
  if (!strength) {
    return 
  }
  passwordValidationErrors.value = false
  const validationUser = $route.params.validation_user
  const validationToken = $route.params.validation_token
  emailValidationSuccess.value = false
  await authStore.validateEmail(validationUser, validationToken, password.value, password2.value).catch(
    function (error) { 
      console.log('registerError');
      console.log(error);
      passwordValidationErrors.value = error
    }
  ).then((response) => {
    if (passwordValidationErrors.value === false) {
      // TODO i18n this
      passwordValidationSuccess.value = "Your email has been validated."
      registrationComplete.value = response.registration_complete
      if (authStore.user === null) {
        window.location.assign('/login')
        return
      }
      if (isApp) {
        // this forces the app to reload when doing this, in order to lose any state.
        // router.push here just results in orphaned pages with data in them
        if (registrationComplete.value === true) {
          window.location.assign('/dashboard/')
        } else {
          window.location.assign('/')
        }
      } else {
        if (registrationComplete.value === true) {
          window.location.assign('/dashboard/')
        } else {
          window.location.assign('/register/')
        }
      }
    }
  })
}
</script>

<template>
  <ion-page>
    <ion-content id="loginview">
      <div ref="loadingContainer" id="loadingContainer" />
      <site-header v-if="isApp === false" :needsReload="true" />
        <section  class="loginForm my-5 py-4" >
        <div v-if="!isLoading" class="container">
          <div class="row justify-content-center my-5">
            <div class="col-xl-6 col-sm-8">
              <h1 class="h1-title text-center">{{ t('register.lets-get-started') }}</h1>
            </div>
          </div>
          <div v-if="emailValidation" class="row justify-content-center">
            <div id="validationDiv" class="col-xl-6 col-sm-8 align-self-center justify-content-center">
              <div v-if="emailValidationErrors" class="alert alert-danger" role="alert">
                <template v-if="emailValidationErrors">
                  {{ emailValidationErrors }}
                </template>
              </div>
              <template v-else-if="emailValidationSuccess">
                <div class="alert alert-success" role="alert">
                  {{ emailValidationSuccess }}
                </div>
                <div v-if="passwordValidationErrors" class="alert alert-danger" role="alert">
                    {{ passwordValidationErrors }}
                </div>
                <FormKit type="form" @submit="submitPasswordHandler" form-class="outer-container">
                  <FormKit 
                    name="password"
                    type="password" 
                    :label="t('register.your-password')" 
                    v-model="password"
                    :errors="passwordErrors"
                    validation="required|password" 
                    suffix-icon="eyeClosed"
                    @suffix-icon-click="handleIconClick"
                    suffix-icon-class="hover:text-blue-500"
                  />
                  <FormKit 
                    name="password_confirm"
                    type="password" 
                    :label="t('register.your-password-again')" 
                    v-model="password2"
                    :errors="password2Errors"
                    validation="required|confirm" 
                    suffix-icon="eyeClosed"
                    @suffix-icon-click="handleIconClick"
                    suffix-icon-class="hover:text-blue-500"
                  />
                </FormKit>
              </template>
              <div v-else-if="passwordValidationSuccess">
                <div class="alert alert-success" role="alert">
                  {{ passwordValidationSuccess }}
                </div>
                <div v-if="authStore.user && !registrationComplete">
                  <router-link v-if="isApp" to="/" class="btn btn-primary">{{ t('register.finish-registration') }}</router-link>
                  <router-link v-else to="/register" class="btn btn-primary">{{ t('register.finish-registration') }}</router-link>
                </div>
                <div v-else-if="authStore.user">
                  <router-link to="/dashboard" class="btn btn-primary">{{ t('register.continue-to-dashboard') }}</router-link>
                </div>
                <div v-else>
                  <router-link v-if="isApp" to="/" class="btn btn-primary">{{ t('register.login-to-continue') }}</router-link>
                  <router-link v-else to="/login" class="btn btn-primary">{{ t('register.login-to-continue') }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="cityBgBottom"></div>
      <site-footer v-if="isApp === false" />
    </ion-content>
  </ion-page>
</template>
<style scoped>
  ion-content {
    --background: unset !important;
    background-image: url('@/assets/images/lingoing-bg-icons.png');
    background-size: 100%;
    background-repeat: repeat;
  }

.formkit-outer {
  margin: 0 auto 1em auto;
}
.input-group {
  display: flex;
  gap: 1em;
}
.formkit-outer[data-type="multi-step"] > .formkit-wrapper {
  margin: 0 auto 1em auto;
}
.formkit-actions > .formkit-outer > div.formkit-wrapper {
  margin: 0 auto 1em auto;
}

#validationDiv .formkit-outer > .formkit-wrapper {
  margin: 0 auto 1em auto;
}
.formkit-outer >>> .formkit-tab-label {
  color: black;
}
div >>> .formkit-tab-label {
  font-size: 0.75em !important;
}
* >>> .vl-overlay {
     background-image: url('@/assets/images/appLoading.png') !important;
     background-size: 100%;
     repeat: no-repeat;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
