<script setup>
  import { ref, onMounted } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import TestimonialSlider from '@/components/TestimonialSlider.vue'
  import PricingPlans from '@/components/PricingPlans.vue'
  import CookieBanner from '@/components/CookieBanner.vue'
  import { IonContent, IonPage } from '@ionic/vue'
  import supportedLanguages from '../locales/languages.json'
  import { getLanguageIdByName } from '@/helpers/utils.js'
  import allLanguages from '../locales/languageIds.json'
  import { useRoute } from 'vue-router'
  const $route = useRoute()
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const authStore = ref(false)
  authStore.value = useAuthStore()

  onMounted(() => {
    if ($route.hash) {
      const element = document.getElementById($route.hash.slice(1))
      if (element) {
         setTimeout(function () {
          element.scrollIntoView({ behavior: 'smooth' })
         }, 500)
      }
    }
  })
  if ($route.hash) {
    const element = document.getElementById($route.hash.slice(1))
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

</script>

<template>
  <ion-page>
    <ion-content id="homeview">
      <site-header :needsReload="false" />
      <!-- Hero Section -->
      <section class="hero-section cityBgBottom mt-110 pb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7">
              <h1 class="h1-title mb-4">{{ t('home.the-ultimate-language-learning-experience') }}</h1>
              <p>{{ t('home.discover-lingo-ing-your-ai-powered-language-tutor--supercharge-your', { num_languages: allLanguages.length}) }}</p>
              <div class="row justify-content-center my-5">
                <div class="col-6 col-sm-3">
                  <div class="language-item">
                    <img src="@/assets/images/flags/English.png" alt="America Flag">
                    <span>{{ t(`language.name.${getLanguageIdByName('English')}`) }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3">
                  <div class="language-item">
                    <img src="@/assets/images/flags/German.png" alt="Germany Flag">
                    <span>{{ t(`language.name.${getLanguageIdByName('German')}`) }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3">
                  <div class="language-item">
                    <img src="@/assets/images/flags/French.png" alt="France Flag">
                    <span>{{ t(`language.name.${getLanguageIdByName('French')}`) }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3">
                  <div class="language-item">
                    <img src="@/assets/images/flags/Portuguese.png" alt="Brazil Flag">
                    <span>{{ t(`language.name.${getLanguageIdByName('Portuguese')}`) }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3">
                  <div class="language-item">
                    <img src="@/assets/images/flags/Italian.png" alt="Italy Flag">
                    <span>{{ t(`language.name.${getLanguageIdByName('Italian')}`) }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3">
                  <div class="language-item">
                    <img src="@/assets/images/flags/Spanish.png" alt="Span Flag">
                    <span>{{ t(`language.name.${getLanguageIdByName('Spanish')}`) }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3">
                  <div class="language-item">
                    <img src="@/assets/images/flags/Japanese.png" alt="Japan Flag">
                    <span>{{ t(`language.name.${getLanguageIdByName('Japanese')}`) }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3">
                  <div class="language-item">
                    <img src="@/assets/images/flags/Chinese.png" alt="China Flag">
                    <span>{{ t(`language.name.${getLanguageIdByName('Chinese')}`) }}</span>
                  </div>
                </div>
              </div>
              <div class="hero-cta my-5 pt-4">
                <router-link to="/register/" class="btn btn-primary-lg btn-primary-cs text-decoration-none mb-4">{{ t('home.start-your-7-day-free-trial-today') }}</router-link>
                <!-- <router-link to="/" class="text-decoration-none d-block fnt-weight-500">Watch demo video <span class="roundedIcon"><i class="bi bi-play-circle-fill"></i></span></router-link> -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End -->

      <!-- Features -->
      <section class="Features mt-110">
        <div class="container">
          <h2 class="h2-title mb-4">{{ t('home.all-you-need-for-rapid-fluency') }}</h2>
          <div class="row py-5 row-gap-5">
            <div class="col-md-4">
              <div class="row align-items-center">
                <div class="col-xl-3 text-center-wk">
                  <div class="col-rounded-icon rounded-icon-red">
                    <img src="@/assets/images/users-graduate-icon.png" alt="">
                  </div>
                </div>
                <div class="col-xl-9 text-xl-start">
                  <h4 class="fnt-20 fnt-weight-700 my-0 text-red ms-2">{{ t('home.join-our-rapidly-growing-community-of-learners') }}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row align-items-center">
                <div class="col-xl-3 text-center-wk">
                  <div class="col-rounded-icon rounded-icon-blue">
                    <img src="@/assets/images/world-globe-icon.png" alt="">
                  </div>
                </div>
                <div class="col-xl-9 text-xl-start">
                  <h4 class="fnt-20 fnt-weight-700 my-0 text-blue ms-2">{{ t('home.numlanguages+-languages-supported', { num_languages: allLanguages.length}) }}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row align-items-center">
                <div class="col-xl-3 text-center-wk">
                  <div class="col-rounded-icon rounded-icon-green">
                    <img src="@/assets/images/graduation-hat-icon.png" alt="">
                  </div>
                </div>
                <div class="col-xl-9 text-xl-start">
                  <h4 class="fnt-20 fnt-weight-700 my-0 text-green ms-2">{{ t('home.customizable-friendly-ai-tutors-for-each-language') }}</h4>
                </div>
              </div>
            </div>
          </div>
          <hr class="hr-sm">
          <div id="features" class="row pt-60 row-gap-5">
            <div class="col-lg-6 text-start">
              <h3 class="h3-title-lg fnt-weight-700 mb-4">{{ t('home.key-features-that-accelerate-your-learning') }}</h3>
              <p>{{ t('home.explore-the-innovative-features-designed-to-enhance-your') }}</p>
              <router-link to="/register/" class="btn btn-primary-lg btn-primary-cs text-decoration-none my-4">{{ t('home.start-your-7-day-free-trial-today') }}</router-link>
            </div>
            <div class="col-lg-6">
              <div class="row justify-content-lg-end mb-5">
                <div class="col-3 col-md-2">
                  <div class="col-rounded-icon rounded-icon-red py-3">
                    <img src="@/assets/images/user-dynamic-icon.png" alt="">
                  </div>
                </div>
                <div class="col-8 col-md-9 text-start ms-md-2">
                  <h4 class="fnt-20 fnt-weight-700 text-red">{{ t('home.dynamic-language-exchanges') }}</h4>
                  <p class="mb-0">{{ t('home.immerse-yourself-in-engaging-dialogues-that-maximize-language') }}</p>
                </div>
              </div>
              <div class="row justify-content-lg-end mb-5">
                <div class="col-3 col-md-2">
                  <div class="col-rounded-icon rounded-icon-blue py-3">
                    <img src="@/assets/images/user-chat-icon.png" alt="">
                  </div>
                </div>
                <div class="col-8 col-md-9 text-start ms-md-2">
                  <h4 class="fnt-20 fnt-weight-700 text-blue">{{ t('home.instant-language-guidance') }}</h4>
                  <p class="mb-0">{{ t('home.elevate-your-learning-with-real-time-customized-insights-and') }}</p>
                </div>
              </div>
              <div class="row justify-content-lg-end">
                <div class="col-3 col-md-2">
                  <div class="col-rounded-icon rounded-icon-green py-3">
                    <img src="@/assets/images/user-voice-icon.png" alt="">
                  </div>
                </div>
                <div class="col-8 col-md-9 text-start ms-md-2">
                  <h4 class="fnt-20 fnt-weight-700 text-green">{{ t('home.adaptive-listening-mastery') }}</h4>
                  <p class="mb-0">{{ t('home.refine-your-comprehension-skills-through-our-dynamic-tailor-made') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section class="why-lingo mt-110 bg-light-blue py-70">
        <div class="container">
          <div class="row justify-content-center ">
            <div class="col-lg-8">
              <h2 class="h2-title mb-4">{{ t('home.why-you-should-choose-lingo-ing-premium') }}</h2>
              <p>{{ t('home.experience-rapid-language-mastery-with-lingo-ing-premiums-advanced') }}</p>
            </div>
          </div>
          <div class="row my-5 row-gap-5">
            <div class="col-lg-4 text-center-wk">
              <div class="col-rounded-icon rounded-icon-red py-3">
                <img src="@/assets/images/interactive-scenarios-icon.png" alt="">
              </div>
              <div class="mw-335">
                <h4 class="fnt-20 fnt-weight-700 my-3">{{ t('home.interactive-scenarios-&-lifelike-characters') }}</h4>
                <p>{{ t('home.dive-into-engaging-roleplays-debates-and-realistic-situations') }}</p>
              </div>
            </div>
            <div class="col-lg-4 text-center-wk">
              <div class="col-rounded-icon rounded-icon-blue py-3">
                <img src="@/assets/images/trophy-icon.png" alt="">
              </div>
              <div class="mw-335">
                <h4 class="fnt-20 fnt-weight-700 my-3">{{ t('home.over-+300-unique-learning-experiences') }}</h4>
                <p>{{ t('home.benefit-from-tailored-engaging-sessions-that-align-with') }}</p>
              </div>
              </div>
            <div class="col-lg-4 text-center-wk">
              <div class="col-rounded-icon rounded-icon-green py-3">
                <img src="@/assets/images/coud-connection-icon.png" alt="">
              </div>
              <div class="mw-335">
                <h4 class="fnt-20 fnt-weight-700 my-3">{{ t('home.customized-learning-experience') }}</h4>
                <p>{{ t('home.enjoy-limitless-real-time-translations-and-bespoke-feedback-to') }}</p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <router-link to="/register/" class="btn btn-primary-lg btn-primary-cs text-decoration-none mb-lg-4">{{ t('home.start-your-7-day-free-trial-today') }}</router-link>
          </div>
        </div>
      </section>
      <!-- End -->

      <!-- pricing plans -->
      <section id="pricing" class="pricing-plans mt-100 pb-lg-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <h2 class="h2-title mb-4">{{ t('home.discover-your-ideal-plan-with-lingo-ing') }}</h2>
              <p>{{ t('home.start-your-free-trial-today-and-explore-our') }}</p>
            </div>
          </div>
          <PricingPlans />
        </div>
      </section>
      <!-- End -->

      <!-- Testimonial -->
      <section class="testimonials bg-light-blue py-70">
        <div class="container">
          <div class="row text-start my-lg-5">
            <div class="col-lg-7 dp-lg-none">
              <img src="@/assets/images/peoples-across-world.png" class="img-fluid" alt="">
            </div>
            <div class="col-lg-5">
              <h2 class="h2-title mb-4">{{ t('home.see-why-users-love-lingo-ing') }}</h2>
              <TestimonialSlider />
            </div>
          </div>
        </div>
      </section>
      <!-- End -->

      <!-- Frequently Asked Questions -->
      <section id="faq" class="pricing-plans mt-100 pb-lg-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <h2 class="h2-title mb-4">{{ t('home.frequently-asked-questions') }}</h2>
              <p>
              {{ t('home.welcome-to-our-faq-section-here-youll-find') }}
              </p>
            </div>
          </div>
          <div class="row justify-content-center my-5">
            <div class="col-lg-9">
              <div class="accordion" id="accordionExample">
                <div class="accordionItem">
                  <h3 class="mb-0">
                    <button class="accordion-btn w-100 text-start fnt-20 fnt-weight-700 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      {{ t('home.how-does-the-ai-personalize-my-learning-experience') }}
                    </button>
                  </h3>
                  <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <p class="text-start mb-0 pt-2 pb-4">{{ t('home.our-ai-assesses-your-language-level-learning-pace') }}</p>
                  </div>
                </div>
                <div class="accordionItem">
                  <h3 class="mb-0">
                    <button class="accordion-btn collapsed w-100 text-start fnt-20 fnt-weight-700 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      {{ t('home.what-languages-are-supported-by-lingo-ing') }}
                    </button>
                  </h3>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <p class="text-start mb-0 pt-2 pb-4">{{ t('home.we-currently-support-the-following-languages:') }} <span v-for="language, value, index in supportedLanguages" v-bind:key="value"><span v-if="index !== 0">,&nbsp;</span>{{ value }}</span></p>
                  </div>
                </div>
                <div class="accordionItem">
                  <h3 class="mb-0">
                    <button class="accordion-btn collapsed w-100 text-start fnt-20 fnt-weight-700 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      {{ t('home.is-there-a-free-trial-available') }}
                    </button>
                  </h3>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <p class="text-start mb-0 pt-2 pb-4">{{ t('home.yes-we-offer-a-7-day-free-trial-experience') }}</p>
                  </div>
                </div>
                <div class="accordionItem">
                  <h3 class="mb-0">
                    <button class="accordion-btn collapsed w-100 text-start fnt-20 fnt-weight-700 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      {{ t('home.can-i-use-lingo-ing-on-multiple-devices') }}
                    </button>
                  </h3>
                  <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <p class="text-start mb-0 pt-2 pb-4"></p>
                    {{ t('home.absolutely--no-matter-how-many-phones-tablets') }}
                  </div>
                </div>
                <div class="accordionItem">
                  <h3 class="mb-0">
                    <button class="accordion-btn collapsed w-100 text-start fnt-20 fnt-weight-700 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                      {{ t('home.what-makes-lingo-ing-different-from-other-language-learning') }}
                    </button>
                  </h3>
                  <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <p class="text-start mb-0 pt-2 pb-4">{{ t('home.our-language-learning-app-features-an-intuitive-design') }}</p>               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End -->

      <section class="download-lingo">
        <div class="container">
          <div class="row text-sm-start justify-content-evenly">
            <div class="col-lg-10 mb-5 pb-5">
              <h2 class="h2-title mb-5">Download Lingo.ing now !</h2>
              <div class="download-btns">
                <!-- <router-link to="/" class="text-decoration-none mb-sm-4"><img src="@/assets/images/google-store-button.png" alt=""></router-link> -->
                <a href="https://apps.apple.com/ca/app/lingo-ing-ai-language-tutor/id6581480179" class="text-decoration-none mb-sm-4 ms-sm-4"><img src="@/assets/images/apple-store-button.png" alt=""></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <cookie-banner />
      <!-- End -->
      <site-footer />
    </ion-content>
  </ion-page>
</template>

<style scoped>
  ion-content {
		--background: unset !important;
    background-size: 100%;
    background-repeat: repeat;
  }
	h3 > button {
		color: var(--bs-heading-color) !important;
	}
  #homeview {
    font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
