<script setup>
  import { defineProps, defineEmits } from 'vue'
  import { ref, inject } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { fetchWrapper, getBaseUrl } from '@/helpers/fetch-wrapper'
  const props = defineProps({
    vocab: Object
  })
  const audiomanager = inject('audiomanager')
  const emit = defineEmits(['reloadList'])
  const vocab = ref(props.vocab)
  async function add_vocab(vocab) {
    vocab.saved = true
    await fetchWrapper.post(`/vocab/add/`, { word: vocab.word, definition: vocab.definition, learning_language: vocab.learning_language, native_language: vocab.native_language })
  }
  async function remove_vocab(vocab) {
    vocab.saved = false
    await fetchWrapper.post(`/vocab/remove/`, { word: vocab.word, learning_language: vocab.learning_language, native_language: vocab.native_language })
    emit('reloadList')
  }
  function play_audio(message) {
    audiomanager.play(message.audio).then(() => {
      console.log('audio played')
    // eslint-disable-next-line no-unused-vars
    }).catch(() => {
      // console.log('audio error', error)
      setTimeout(() => play_audio(message), 100)
    })
  }
  function play_vocab_audio(vocab) {
    const { user } = useAuthStore()
    vocab.audio = getBaseUrl(`/tts/?lang=${vocab.learning_language}&text=${vocab.word}&token=${user.token}`)
    setTimeout(() => play_audio(vocab), 100)
  }
</script>
<template>
  <div class="ml-2 mt-1">
    <div class="card border-light shadow-sm">
      <div class="card-body">
        <div class="row">
          <div class="col-10">
            <span v-if="vocab.annotation" v-html="vocab.annotation"></span>
            <span v-else v-html="vocab.word"></span><br/>
            <span v-html="vocab.definition"></span><br/>
            <span v-html="vocab.example"></span><br/>
          </div>
          <div class="col-2">
            <i v-if="vocab.saved" @click="remove_vocab(vocab)" class="action-icon bi-hand-thumbs-up-fill"></i>
            <i v-else @click="add_vocab(vocab)" class="action-icon bi-hand-thumbs-up"></i>
            <i @click="play_vocab_audio(vocab)" class="action-icon bi-play-circle"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.action-icon {
  font-size: 150%;
  cursor: pointer;
}
</style>
