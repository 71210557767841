import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper'

export const useProfileStore = defineStore({
  id: 'profile',
  state: () => ({
    profile: JSON.parse(localStorage.getItem('profile')),
  }),
  actions: {
    async fetchProfile() {
      const profile = await fetchWrapper.get(`/profile/`)
      this.profile = profile
      localStorage.setItem('profile', JSON.stringify(profile))
    },
    async changeLanguage(language) {
      this.profile.learning_language = language
      await fetchWrapper.patch(`/profile/`, { learning_language: language })
    }
  }
})

