/* designed to be used as a singleton, and injected into all components
so we have a single point of entry to audio, which avoids multiple audio
playing over top of each other, and allows us to manage the icon states
across different components.  also, by not having actual audio elements
in the html this avoids pre-loading the TTS urls, and saves money. */
export class AudioManager {
  constructor() {
    this.audio = new Audio()
    this.playback_speed = 1
    this.playing = false
    this.onended = () => {}  // default to a no-op
  }
  // should only be called from the settings modal
  setPlaybackSpeed(speed) {
    this.playback_speed = speed
  }
  play(url) {
    console.log("play url", url)
    this.onended_wrapper()
    this.audio.src = url
    // setting the audio source resets the playback rate
    console.log("setting playback rate to", this.playback_speed)
    this.audio.playbackRate = this.playback_speed
    return this.play_with_retry(url)
  }
  /* if the audio fails to play, try again with exponential backoff and a hard limit of 5 attempts */
  async play_with_retry(url) {
    let attempts = 0
    while (attempts < 5) {
      try {
        this.audio.src = url
        this.audio.playbackRate = this.playback_speed
        this.audio.onended = this.onended_wrapper.bind(this)
        await this.audio.play()
        this.playing = true
        console.log('play succeeded after', attempts, 'attempts')
        return
      } catch (err) {
        attempts++
        console.error('trapped play error', err, 'retrying', attempts)
        await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, attempts)))
      }
    }
  }
  onended_wrapper() {
    this.playing = false
    this.onended()
  }
  pause() {
    this.audio.pause()
    this.onended_wrapper()
  }
  /* basically so we can reset the pause icon to be a play icon */
  setOnEnded(callback) {
    this.onended = callback
    this.audio.onended = this.onended_wrapper.bind(this)
  }
}

